/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { useEffect, useState } from "react";
import { PAGES } from "../../../common/constants/common";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import {
  Col,
  Link,
  Loader,
  Row,
  Separator,
  Text,
  Title,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchElectricityOffer } from "../../auth/auth.actions";
import {
  electricityOfferSelector,
  isFetchingAuthSelector,
} from "../../auth/auth.selectors";
import styles from "./EnergyOffers.module.scss";
import Offer from "./Offer";

const EnergyOffers = () => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingAuthSelector);
  const electricityOffer = useAppSelector(electricityOfferSelector);
  const [offer, setOffer] = useState<string | undefined>();

  useEffect(() => {
    dispatch(fetchElectricityOffer());
  }, []);

  useEffect(() => {
    setOffer(electricityOffer?.econtractType);
  }, [electricityOffer]);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Row align="start" direction="column">
      <Col span={12}>
        <Title>Votre énergie de demain, par votre opérateur de proximité</Title>
        <Separator />
        <Text size="large">
          Nos offres d’énergie vous permettent de choisir le moyen de production
          et la provenance de votre électricité les plus adaptés. Découvrez les
          produits SIE.
        </Text>
        <Text size="large">
          {electricityOffer?.econtractType &&
            `Votre offre est actuellement: ${electricityOffer?.econtractType}`}
          {/* {electricityOffer?.econtractType !== null && `Votre offre est actuellement: ${electricityOffer?.econtractType}`} */}
        </Text>
      </Col>
      <Separator size="large" />
      <Separator size="large" />
      <Col span={12}>
        {offer?.includes("Nature") && (
          <>
            <Offer type="NATURE" />
            <Offer type="NATURE_PLUS" />
          </>
        )}
        {offer === "BASIC" && <Offer type="BASIC" />}
        {offer === "HYDRO" && <Offer type="HYDRO" />}
        {offer === "HYDRO_PLUS" && <Offer type="HYDRO_PLUS" />}
        {!offer && (
          <Row align="start" direction="column" justify="start">
            <Title level={2}>
              Offre personnalisée : Notre offre sur mesure pour vos besoins.{" "}
            </Title>
            <Text size="large">
              Vous bénéficiez actuellement d&apos;une offre personnalisée. Pour
              plus d&apos;informations,&nbsp;
              <Link
                className={styles.contactAndFormsLink}
                to={PAGES.CONTACT_AND_FORMS}
              >
                merci de prendre contact avec nos services
              </Link>
              .
            </Text>
          </Row>
        )}
        <Separator size="large" />
        <Row justify="start">
          <Text>
            Vous souhaitez changer d’offre ?{" "}
            <Link to={PAGES.CONTACT_AND_FORMS} onClick={trackClickHandler}>
              Contactez-nous
            </Link>{" "}
            pour définir ensemble quelle offre correspond le mieux à votre
            profil.
          </Text>
        </Row>
      </Col>
    </Row>
  );
};

export default EnergyOffers;
