/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */

export const CHECK_ADMIN_CONNECTION_START =
  "ADMIN::CHECK_ADMIN_CONNECTION_START";
export const CHECK_ADMIN_CONNECTION_SUCCESS =
  "ADMIN::CHECK_ADMIN_CONNECTION_SUCCESS";
export const CHECK_ADMIN_CONNECTION_FAILURE =
  "ADMIN::CHECK_ADMIN_CONNECTION_FAILURE";

export const DELETE_USER_START = "ADMIN::DELETE_USER_START";
export const DELETE_USER_SUCCESS = "ADMIN::DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "ADMIN::DELETE_USER_FAILURE";

export const IMPERSONATE_USER_START = "ADMIN::IMPERSONATE_USER_START";
export const IMPERSONATE_USER_SUCCESS = "ADMIN::IMPERSONATE_USER_SUCCESS";
export const IMPERSONATE_USER_FAILURE = "ADMIN::IMPERSONATE_USER_FAILURE";

export const EXTRACT_NEWSLETTER_START = "ADMIN::EXTRACT_NEWSLETTER_START";
export const EXTRACT_NEWSLETTER_SUCCESS = "ADMIN::EXTRACT_NEWSLETTER_SUCCESS";
export const EXTRACT_NEWSLETTER_FAILURE = "ADMIN::EXTRACT_NEWSLETTER_FAILURE";

export const DELETE_NEWSLETTER_START = "ADMIN::DELETE_NEWSLETTER_START";
export const DELETE_NEWSLETTER_SUCCESS = "ADMIN::DELETE_NEWSLETTER_SUCCESS";
export const DELETE_NEWSLETTER_FAILURE = "ADMIN::DELETE_NEWSLETTER_FAILURE";

export const RETRIEVE_USER_START = "ADMIN::RETRIEVE_USER_START";
export const RETRIEVE_USER_SUCCESS = "ADMIN::RETRIEVE_USER_SUCCESS";
export const RETRIEVE_USER_FAILURE = "ADMIN::RETRIEVE_USER_FAILURE";

export const RESENDING_VERIFICATION_START =
  "ADMIN::RESENDING_VERIFICATION_START";
export const RESENDING_VERIFICATION_END = "ADMIN::RESENDING_VERIFICATION_END";
