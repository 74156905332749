/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import { Button, Col, Input, Row, Title, Text, Separator } from '../../../components/ui';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { isSubmittingPublicSelector } from '../public.selectors';
import { PAGES, SPECIAL_CHARACTERS } from '../../../common/constants/common';
import { resetPassword } from '../public.actions';
import { resetPasswordFormData } from '../public.types';
import styles from './ResetPassword.module.scss';

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const isSubmitting = useAppSelector(isSubmittingPublicSelector);
  const {
    register, handleSubmit, setError, watch, formState: { errors }
  } = useForm({ defaultValues: {
    newPassword: '',
    retypePassword: '',
  },
  shouldUnregister: true,
  reValidateMode: 'onChange',
  mode: 'onChange' });

  const newPassword = useRef({});
  newPassword.current = watch('newPassword', '');

  const onResetPassword = (data: resetPasswordFormData) => {
    const urlParams = new URLSearchParams(search);

    dispatch(resetPassword({
      newpassword: data.newPassword,
      token: urlParams.get('token') || '',
      email: urlParams.get('email') || ''
    }, setError));
  };

  return (
    <Row className={styles.signIn} direction="column">
      <Col
        lg={6}
        md={8}
        span={10}
        xl={3}
      >
        <Title align="center" level={1}>Saisir mon nouveau mot de passe</Title>
        <Text align="center" size="large">
          Veuillez saisir un nouveau mot de passe.
          &nbsp;Vous pourrez ainsi à nouveau accéder à votre Espace Client.
        </Text>
        <Separator size="large" />
      </Col>
      <Col
        lg={4}
        md={5}
        span={10}
        xl={3}
        xxl={2}
      >
        <form onSubmit={handleSubmit(onResetPassword)}>
          <Separator />
          <Input
            {...register('newPassword', {
              required: true,
              minLength: 8,
              validate: {
                hasLowerCase: value => /[a-z]+/.test(value),
                hasUpperCase: value => /[A-Z]+/.test(value),
                hasNumber: value => /[0-9]+/.test(value),
                hasSpecialCharacter: value => SPECIAL_CHARACTERS.some(
                  (specialCharacter) => value.includes(specialCharacter)
                )
              }
            })
            }
            isLabelBolded
            error={
              (errors.newPassword?.type === 'required' && 'Mot de passe requis')
              || (errors.newPassword?.type === 'minLength' && 'Votre mot de passe ne répond pas aux exigences de sécurité demandées: être composé au minimum de 8 caractères.')
              || (errors.newPassword?.type === 'hasLowerCase' && 'Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins une lettre minuscule latine (de a à z).')
              || (errors.newPassword?.type === 'hasUpperCase' && 'Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins une lettre majuscule latine (de A à Z).')
              || (errors.newPassword?.type === 'hasNumber' && 'Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins un chiffre (de 0 à 9).')
              || (errors.newPassword?.type === 'hasSpecialCharacter' && 'Votre mot de passe ne répond pas aux exigences de sécurité demandées: contenir au moins un caractère spécial (exemple : ?, !).')
              || (errors.newPassword?.type === 'sameAsOldPassword' && 'Votre nouveau mot de passe ne peut pas être le même que votre ancien mot de passe.')
            }
            label="Mot de passe"
            type="password"
          />
          <Separator />
          <Input
            {...register('retypePassword', {
              required: true,
              validate: value => value === newPassword.current })}
            isLabelBolded
            error={
              (errors?.retypePassword?.type === 'required' && 'Veuillez confirmer le mot de passe')
              || (errors?.retypePassword?.type === 'validate' && 'Les mots de passe ne correspondent pas')
            }
            label="Confirmation du mot de passe"
            type="password"
          />
          <Separator />
          <Row>
            <Button
              buttonStyle="secondaryButton"
              className={styles.resetPasswordSecondaryButton}
              type="button"
              onClick={() => history.push(PAGES.SIGN_IN)}
            >
              Annuler
            </Button>
            <Button
              className={styles.resetPasswordButton}
              loading={isSubmitting}
              type="submit"
            >Valider
            </Button>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default ResetPassword;
