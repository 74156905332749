/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppAction } from "../../../../redux/actionType";
import * as types from "./dashboard.actionTypes";

const initialState = {
  isComplex: null,
  lastInvoice: null,
  consumption: null,
  multimediaContract: null,
  electricityContract: null,
  isFetchingLastInvoiceAndElectricityContract: false,
  isFetching: false,
  isSubmitting: false,
};

// eslint-disable-next-line
export default (state = initialState, action: AppAction) => {
  switch (action?.type) {
    case types.FETCH_DASHBOARD_DATA_START:
      return {
        ...state,
        isFetching: true,
        isFetchingLastInvoiceAndElectricityContract: true,
      };
    case types.FETCH_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        isComplex: action.payload.isComplex,
        consumption: action.payload.consumption,
        multimediaContract: action.payload.multimediaContract,
        electricityContract: action.payload.electricityContract,
        isFetching: false,
      };
    case types.FETCH_LAST_INVOICE_AND_ELECTRICITY_CONTRACT_SUCCESS:
      return {
        ...state,
        lastInvoice: action.payload.lastInvoice,
        electricityContract: action.payload.electricityContract,
        isFetchingLastInvoiceAndElectricityContract: false,
      };
    case types.FETCH_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetchingLastInvoiceAndElectricityContract: false,
      };
    case types.SUBSCRIBE_TO_NEWSLETTER_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case types.SUBSCRIBE_TO_NEWSLETTER_SUCCESS:
    case types.SUBSCRIBE_TO_NEWSLETTER_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return { ...state };
  }
};
