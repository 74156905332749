/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import axios from "./http";

const apiConsumptionSuffix = "/info";

export const fetchConsumptionAddressesApi = (userId) =>
  axios({
    method: "GET",
    url: `${apiConsumptionSuffix}/consumptionaddress/${userId}`,
  });

export const fetchConsumptionHistoryApi = async (userId, data, token) =>
  axios({
    method: "POST",
    url: `${apiConsumptionSuffix}/consumptiontimeseries/${userId}`,
    data,
    cancelToken: token,
  });

export const fetchConsumptionCsvApi = (userId, data) =>
  axios({
    method: "POST",
    url: `${apiConsumptionSuffix}/consumptiongraphcsv/${userId}`,
    data,
  });
