/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import styles from "./FooterContact.module.scss";
import Text from "../text/Text";

type FooterContactProps = {
  contactTitle?: string;
  footerAddress?: string;
  footerMunicipality?: string;
  footerTelephone?: string;
  footerEmail?: string;
};

const FooterContact = ({
  contactTitle = "",
  footerAddress = "",
  footerMunicipality = "",
  footerTelephone = "",
  footerEmail = "",
}: FooterContactProps) => (
  <div className={styles.footerContactItem}>
    <Text align="center" className={styles.footerContactItemTitle}>
      {contactTitle}
    </Text>
    <Text align="center" className={styles.footerAddress}>
      {footerAddress}
    </Text>
    <Text align="center" className={styles.footerMunicipality}>
      {footerMunicipality}
    </Text>
    <Text align="center" className={styles.footerTelephone}>
      {footerTelephone}
    </Text>
    <Text align="center" className={styles.footerEmail}>
      {footerEmail}
    </Text>
  </div>
);

export default FooterContact;
