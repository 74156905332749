/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { PageNotFoundIcon } from "../../../assets/icons";
import { Col, Row, Title } from "../../../components/ui";
import styles from "../errors.module.scss";

const Error404 = () => (
  <Row direction="column">
    <Col>
      <Title>Page non trouvée</Title>
    </Col>
    <Col>
      <PageNotFoundIcon className={styles.errorIcon} />
    </Col>
  </Row>
);

export default Error404;
