/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { Redirect, useLocation } from "react-router-dom";
import { SESSION_STORAGE, PAGES } from "../../common/constants/common";
import PageLayout from "../organisms/pageLayout/PageLayout";

type PublicRouterProps = {
  page: JSX.Element;
};

const PublicRouter = (props: PublicRouterProps): JSX.Element => {
  const { page } = props;
  const isUserLoggedIn = sessionStorage.getItem(SESSION_STORAGE.ACCESS_TOKEN);
  const location = useLocation();
  const isOnError500page = location.pathname === PAGES.ERROR_500;

  return isUserLoggedIn && !isOnError500page ? (
    <Redirect to={PAGES.DASHBOARD} />
  ) : (
    <PageLayout content={page} type="public" />
  );
};

export default PublicRouter;
