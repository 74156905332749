/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import { MouseEventHandler } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CloseIcon, MecHeaderIcon } from "../../../assets/icons";
import { PAGES } from "../../../common/constants/common";
import { userSelector } from "../../../modules/auth/auth.selectors";
import { useAppSelector } from "../../../redux/hooks";
import { Link } from "../../ui";
import { NAVIGATION_ITEMS } from "./navigation.config";
import styles from "./Navigation.module.scss";

const cx = classNames.bind(styles);

type NavigationProps = {
  type?: "public" | "private" | "admin";
  mobileNavigationOpened?: boolean;
  onTogleMobileNavigation?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
};

const Navigation = ({
  type = "private",
  mobileNavigationOpened = false,
  onTogleMobileNavigation = () => {},
  className = "",
}: NavigationProps) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const user = useAppSelector(userSelector);

  return (
    <nav
      className={cx(
        styles.navigation,
        { [styles.mobileNavigationOpened]: mobileNavigationOpened },
        className
      )}
    >
      {mobileNavigationOpened && (
        <CloseIcon
          className={styles.closeNavigationIcon}
          onClick={onTogleMobileNavigation}
        />
      )}
      <MecHeaderIcon
        className={cx(styles.mecHeaderIcon, {
          [styles.mecHeaderIconHiddenForAdmin]: type === "admin",
        })}
        onClick={() => history.push(PAGES.DASHBOARD)}
      />
      {NAVIGATION_ITEMS.map(
        (item) =>
          !item.hiddenForScenarios.includes(user?.scenario) && (
            <Link
              align="center"
              className={styles.navigationItem}
              key={item.name}
              to={item.pathname}
              onClick={onTogleMobileNavigation}
            >
              <div
                className={cx(styles.navigationItemIcon, {
                  [styles.navigationItemIconActive]: pathname === item.pathname,
                })}
              >
                {item.icon}
              </div>
              <div
                className={cx(styles.navigationItemName, {
                  [styles.navigationItemNameActive]: pathname === item.pathname,
                })}
              >
                {item.name}
              </div>
              {item.separator && (
                <div className={styles.navigationItemSeparator} />
              )}
            </Link>
          )
      )}
    </nav>
  );
};

export default Navigation;
