/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { useForm } from "react-hook-form";
import { HomeIcon, PhoneIcon, PlugIcon } from "../../../assets/icons";
import { PAGES } from "../../../common/constants/common";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import openLinkInNewTab from "../../../common/helpers/openLinkInNewTab";
import { Button, Col, Input, Link, Row, Separator, Text, Title, } from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import styles from "./Dashboard.module.scss";
import { subscribeToNewsletter } from "./service/dashboard.actions";
import { isSubmittingDashboardSelector } from "./service/dashboard.selectors";
import { subscribeToNewsletterRequest } from "./service/dashboard.types";

const Services = () => {
    const isSubmitting = useAppSelector(isSubmittingDashboardSelector);
    const dispatch = useAppDispatch();
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      defaultValues: {
        email: "",
      },
      shouldUnregister: true,
    });

    const onSubscribe = (data: subscribeToNewsletterRequest) => {
      dispatch(subscribeToNewsletter(data, reset));
    };

    return (
      <Row>
        <Col hasPageContentOffset className={styles.services} span={12}>
          <Title isBoxTitle align="center" level={3}>
            Informations utiles
          </Title>
          <Separator />
          <Row wrapForMobile align="start">
            <Col className={styles.servicesIconWrapper} md={3} span={12}>
              <div className={styles.servicesIcon}>
                <HomeIcon />
              </div>
              <Separator />
              <Link
                isLight
                to={PAGES.CONTACT_AND_FORMS}
                onClick={trackClickHandler}
              >
                Je déménage
              </Link>
            </Col>
            <Col className={styles.servicesIconWrapper} md={3} span={12}>
              <div className={styles.servicesIcon}>
                <PlugIcon />
              </div>
              <Separator />
              <Link
                isLight
                align="center"
                to={PAGES.MY_METER_READINGS}
                onClick={trackClickHandler}
              >
                Mes relevés de compteurs
              </Link>
            </Col>
            <Col className={styles.servicesIconWrapper} md={3} span={12}>
              <div className={styles.servicesIcon}>
                <PhoneIcon />
              </div>
              <Separator />
              <Link
                isLight
                to={PAGES.CONTACT_AND_FORMS}
                onClick={trackClickHandler}
              >
                Service clients
              </Link>
              <Text>Tél. : 021 631 50 00</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    );
};

export default Services;
