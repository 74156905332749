/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable indent */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */

import classNames from "classnames/bind";
import React, { ReactNode } from "react";
import { SmallArrowIcon, TableArrowIcon } from "../../../assets/icons";
import Button from "../button/Button";
import ControlledCheckbox from "../checkbox/ControlledCheckbox";
import Col from "../grid/col/Col";
import Row from "../grid/row/Row";
import Loader from "../loader/Loader";
import Radio from "../radio/Radio";
import Separator from "../separator/Separator";
import Title from "../title/Title";
import styles from "./Table.module.scss";
import { PAGINATION } from "../../../common/constants/pagination";

const cx = classNames.bind(styles);

type TableProps = {
  id: string;
  columns?: {
    dataIndex: string;
    title: string | number | ReactNode;
    sort?: "UNSORTED" | "ASC" | "DESC";
    checked?: boolean;
  }[];
  data?: {
    key: string | number;
    checked?: boolean;
    [key: string]: string | number | boolean | ReactNode;
  }[];
  pagination?: boolean;
  hasShowAllButton?: boolean;
  totalResults?: number;
  loadingAllResults?: boolean;
  selectableRows?: boolean; // Radio button for each row (first column only)
  checkableRows?: boolean; // Checkbox for each row (first column only)
  checkableFirstColumn?: boolean; // Checkbox for first column
  currentPage?: number;
  totalPages?: number;
  loading?: boolean;
  noDataMessage?: string;
  onPageChange?: Function;
  onSortChange?: Function;
  // eslint-disable-next-line
  onRowSelect?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line
  onRowChecked?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line
  onColumnChecked?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultSelectedRow?: number;
  onShowAllResults?: Function;
  className?: string;
};

const Table = ({
  id,
  columns = [],
  data = [],
  pagination = false,
  hasShowAllButton = false,
  totalResults = 0,
  loadingAllResults = false,
  selectableRows = false,
  checkableRows = false,
  checkableFirstColumn = false,
  currentPage = 1,
  totalPages = 1,
  loading = false,
  noDataMessage = "Pas de données",
  onPageChange = () => {},
  onSortChange = () => {},
  onColumnChecked = () => {},
  onRowSelect = () => {},
  onRowChecked = () => {},
  defaultSelectedRow,
  onShowAllResults = () => {},
  className = "",
}: TableProps) => (
  <>
    <div className={styles.tableWrapper}>
      <table
        className={cx(
          styles.table,
          { [styles.tableBorderBottom]: !(data.length % 2) },
          className
        )}
      >
        <thead>
          <tr className={styles.tableHeader}>
            {columns.map((column, columnIndex) => (
              <th className={styles.tableColumn} key={column.dataIndex}>
                {checkableFirstColumn && columnIndex === 0 && (
                  <>
                    <ControlledCheckbox
                      hasDarkBorder
                      checked={column.checked}
                      className={styles.columnCheckbox}
                      id={`${column.dataIndex}-${id}`}
                      name={column.dataIndex}
                      onChange={onColumnChecked}
                    />
                    <SmallArrowIcon className={styles.checkboxArrowIcon} />
                  </>
                )}
                {column.title}
                {column.sort && (
                  <button
                    aria-label="sort"
                    className={styles.ascAndDescArrows}
                    type="button"
                    onClick={() =>
                      onSortChange({
                        dataIndex: column.dataIndex,
                        sort: column.sort === "ASC" ? "DESC" : "ASC",
                      })
                    }
                  >
                    <span
                      className={cx({
                        [styles.activeArrow]: column.sort === "DESC",
                      })}
                    >
                      <TableArrowIcon />
                    </span>
                    <span
                      className={cx(styles.ascArrow, {
                        [styles.activeArrow]: column.sort === "ASC",
                      })}
                    >
                      <TableArrowIcon />
                    </span>
                  </button>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((dataItem, dataItemIndex) => (
            <tr className={styles.tableDataRow} key={dataItem.key}>
              {Object.keys(dataItem)
                .filter(
                  (dataItemPureProperty) =>
                    !["key", "checked", "maxWidth"].includes(
                      dataItemPureProperty
                    )
                )
                .map((dataItemPropertyName, dataItemPropertyNameIndex) => (
                  <td
                    className={styles.tableDataCell}
                    key={dataItemPropertyName}
                  >
                    {selectableRows && dataItemPropertyNameIndex === 0 && (
                      <Radio
                        className={styles.tableRadioAndCheckbox}
                        defaultChecked={dataItemIndex === defaultSelectedRow}
                        id={`${dataItem.key}-${id}`}
                        labelClassName={styles.tableRadioAndCheckboxLabel}
                        name={id}
                        value={dataItem.key}
                        onChange={onRowSelect}
                      >
                        <div className={styles.dataItem}>
                          {dataItem[dataItemPropertyName]}
                        </div>
                      </Radio>
                    )}
                    {checkableRows && dataItemPropertyNameIndex === 0 && (
                      <ControlledCheckbox
                        checked={dataItem.checked}
                        className={styles.tableRadioAndCheckbox}
                        id={`${dataItem.key}-${id}`}
                        labelClassName={styles.tableRadioAndCheckboxLabel}
                        name={
                          typeof dataItem.key === "number"
                            ? `${dataItem.key}`
                            : dataItem.key
                        }
                        onChange={onRowChecked}
                      >
                        <span className={styles.dataItem}>
                          {dataItem[dataItemPropertyName]}
                        </span>
                      </ControlledCheckbox>
                    )}
                    {!selectableRows &&
                      !checkableRows &&
                      dataItemPropertyNameIndex === 0 && (
                        <span className={styles.dataItem}>
                          {dataItem[dataItemPropertyName]}
                        </span>
                      )}
                    {dataItemPropertyNameIndex > 0 && (
                      <span className={styles.dataItem}>
                        {dataItem[dataItemPropertyName]}
                      </span>
                    )}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
      {(!data || !data.length) && (
        <Title align="center" level={3}>
          {noDataMessage}
        </Title>
      )}
      {loading && (
        <div className={styles.tableLoadingOverlay}>
          <Loader type="content" />
        </div>
      )}
    </div>
    {pagination && totalPages > 1 && (
      <>
        <Separator />
        <Row className={styles.pagination}>
          {currentPage !== 1 && (
            <Col className={styles.previous}>
              <Button
                buttonStyle="linkButton"
                onClick={() =>
                  currentPage !== 1 && onPageChange(currentPage - 1)
                }
              >
                <span className={styles.previousButtonArrow}>
                  <SmallArrowIcon />
                </span>
                Précédent
              </Button>
            </Col>
          )}
          <Col className={styles.paginationNumbers}>
            <Row>
              {Array.from(Array(totalPages).keys()).map((page) => (
                <Button
                  buttonStyle="paginationButton"
                  isCurrentPaginationPage={page + 1 === currentPage}
                  onClick={() =>
                    page + 1 !== currentPage && onPageChange(page + 1)
                  }
                >
                  {page + 1}
                </Button>
              ))}
            </Row>
          </Col>
          {currentPage !== totalPages && (
            <Col className={styles.next}>
              <Button
                buttonStyle="linkButton"
                onClick={() =>
                  currentPage < totalPages && onPageChange(currentPage + 1)
                }
              >
                Suivant
                <span className={styles.nextButtonArrow}>
                  <SmallArrowIcon />
                </span>
              </Button>
            </Col>
          )}
        </Row>
      </>
    )}
    {hasShowAllButton &&
      data &&
      (totalResults + PAGINATION.ADDRESS_COUNTERS_PER_PAGE) > PAGINATION.ADDRESS_COUNTERS_PER_PAGE && (
        <>
          <Separator />
          <Row>
            <Button
              loading={loadingAllResults}
              onClick={() => onShowAllResults()}
            >
              Afficher tout ({totalResults})
            </Button>
          </Row>
        </>
      )}
  </>
);

export default Table;
