/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

export const FETCH_CONSUMPTION_AND_ADDRESSES_START =
  "CONSUMPTION::FETCH_CONSUMPTION_AND_ADDRESSES_START";
export const FETCH_CONSUMPTION_AND_ADDRESSES_SUCCESS =
  "CONSUMPTION::FETCH_CONSUMPTION_AND_ADDRESSES_SUCCESS";
export const FETCH_CONSUMPTION_AND_ADDRESSES_FAILURE =
  "CONSUMPTION::FETCH_CONSUMPTION_AND_ADDRESSES_FAILURE";

export const FETCH_CONSUMPTION_HISTORY_START =
  "CONSUMPTION::FETCH_CONSUMPTION_HISTORY_START";
export const FETCH_CONSUMPTION_HISTORY_SUCCESS =
  "CONSUMPTION::FETCH_CONSUMPTION_HISTORY_SUCCESS";
export const FETCH_CONSUMPTION_HISTORY_FAILURE =
  "CONSUMPTION::FETCH_CONSUMPTION_HISTORY_FAILURE";

export const FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_START =
  "CONSUMPTION::FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_START";
export const FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_SUCCESS =
  "CONSUMPTION::FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_SUCCESS";
export const FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_FAILURE =
  "CONSUMPTION::FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_FAILURE";

export const DOWNLOAD_CSV_START = "CONSUMPTION::DOWNLOAD_CSV_START";
export const DOWNLOAD_CSV_SUCCESS = "CONSUMPTION::DOWNLOAD_CSV_SUCCESS";
export const DOWNLOAD_CSV_FAILURE = "CONSUMPTION::DOWNLOAD_CSV_FAILURE";
