/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { MouseEventHandler, ReactNode } from "react";
import classNames from "classnames/bind";
import styles from "./Button.module.scss";

const cx = classNames.bind(styles);

type ButtonProps = {
  type?: "button" | "submit" | "reset";
  buttonStyle?:
    | "primaryButton"
    | "secondaryButton"
    | "linkButton"
    | "paginationButton";
  loading?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  disabled?: boolean;
  block?: boolean;
  isCurrentPaginationPage?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  children?: ReactNode;
};

const Button = ({
  type = "button",
  buttonStyle = "primaryButton",
  loading = false,
  prefix = null,
  suffix = null,
  disabled = false,
  block = false,
  isCurrentPaginationPage = false,
  onClick = () => {},
  className = "",
  children,
}: ButtonProps) => (
  <button
    className={cx(
      styles[buttonStyle],
      {
        [styles.blockButton]: block,
        [styles.buttonDisabled]: disabled,
        [styles.buttonLoading]: loading,
        [styles.paginationButtonActive]: isCurrentPaginationPage,
      },
      className
    )}
    disabled={disabled || loading}
    type={type}
    onClick={onClick}
  >
    <div
      className={cx(styles.buttonContent, {
        [styles.buttonContentWithPrefixOrSufix]: prefix || suffix,
        [styles.linkButtonContent]: buttonStyle === "linkButton",
      })}
    >
      {prefix && prefix}
      {children}
      {suffix && suffix}
      {loading && <div className={styles.buttonLoader} />}
    </div>
  </button>
);

export default Button;
