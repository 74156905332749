/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { toast } from "react-toastify";

type toastParams = {
  message?: string;
  timeout?: number | false;
  id?: string | number;
};

export const toastInfo = ({
  message = "",
  timeout = 3000,
  id = new Date().toString(),
}: toastParams) => {
  toast.info(message, {
    autoClose: timeout,
    toastId: id,
    style: {
      fontFamily: "CircularStd",
    },
  });
};

export const toastSuccess = ({
  message = "",
  timeout = 3000,
  id = new Date().toString(),
}: toastParams) => {
  toast.success(message, {
    autoClose: timeout,
    toastId: id,
    style: {
      fontFamily: "CircularStd",
    },
  });
};

export const toastWarning = ({
  message = "",
  timeout = 3000,
  id = new Date().toString(),
}: toastParams) => {
  toast.warning(message, {
    autoClose: timeout,
    toastId: id,
    style: {
      fontFamily: "CircularStd",
    },
  });
};

export const toastError = ({
  message = "",
  timeout = 3000,
  id = new Date().toString(),
}: toastParams) => {
  toast.error(message, {
    autoClose: timeout,
    toastId: id,
    style: {
      fontFamily: "CircularStd",
    },
  });
};
