/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { PAGES } from "../../../common/constants/common";
import {
  Button,
  Col,
  Input,
  Row,
  Separator,
  Text,
  Title,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { forgottenPassword } from "../public.actions";
import { isSubmittingPublicSelector } from "../public.selectors";
import { forgottenPasswordRequestData } from "../public.types";
import styles from "./ForgottenPassword.module.scss";

const ForgottenPassword = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isSubmitting = useAppSelector(isSubmittingPublicSelector);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    shouldUnregister: true,
  });

  const onForgottenPassword = (data: forgottenPasswordRequestData) => {
    dispatch(forgottenPassword(data, setError));
  };

  return (
    <Row direction="column">
      <Col lg={6} md={8} span={10} xl={3}>
        <Title align="center" level={1}>
          Réinitialiser mon mot de passe
        </Title>
        <Text align="center" size="large">
          Pour réinitialiser votre mot de passe, veuillez indiquer votre adresse
          email. &nbsp;Vous recevrez ensuite un lien vous permettant de le
          réinitialiser.
        </Text>
        <Separator size="large" />
      </Col>
      <Col lg={4} md={5} span={10} xl={3} xxl={2}>
        <form onSubmit={handleSubmit(onForgottenPassword)}>
          <Separator />
          <Input
            {...register("email", { required: true })}
            isLabelBolded
            required
            error={
              (errors.email?.type === "required" && "Email est requis") ||
              (errors.email?.type === "incorrectEmail" &&
                "l'e-mail n'existe pas")
            }
            label="Email"
            type="email"
          />
          <Separator />
          <Row>
            <Button
              buttonStyle="secondaryButton"
              className={styles.forgottenPasswordSecondaryButton}
              type="button"
              onClick={() => history.push(PAGES.SIGN_IN)}
            >
              Annuler
            </Button>
            <Button
              className={styles.forgottenPasswordButton}
              loading={isSubmitting}
              type="submit"
            >
              Valider
            </Button>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default ForgottenPassword;
