/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import axios, { AxiosResponse } from "axios";
import {
  downloadInvoicesPdfApi,
  fetchInvoiceReceptionApi,
  fetchInvoicesAddressesApi,
  fetchInvoicesHistoryApi,
  fetchLatestInvoiceApi,
} from "../../../../api/invoices.api";
import { PAGINATION } from "../../../../common/constants/pagination";
import { endOfDay } from "../../../../common/helpers/dates";
import { downloadFromResp } from "../../../../common/helpers/helpers";
import { AppDispatch, AppState } from "../../../../redux/store";
import { toastError, toastInfo } from "../../../../services";
import { signOut } from "../../../auth/auth.actions";
import { userSelector } from "../../../auth/auth.selectors";
import * as types from "./invoices.actionTypes";
import {
  fetchInvoiceReceptionResponseData,
  fetchInvoicesAddressesResponseData,
  fetchInvoicesHistoryRequestParams,
  fetchInvoicesHistoryResponseData,
  fetchLatestInvoiceResponseData,
} from "./invoices.types";

export const fetchLatestInvoice =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_LATEST_INVOICE_START });

      const response: AxiosResponse<fetchLatestInvoiceResponseData> =
        await fetchLatestInvoiceApi(id);

      dispatch({
        type: types.FETCH_LATEST_INVOICE_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.FETCH_LATEST_INVOICE_FAILURE });
    }
  };

export const fetchInvoicesAddresses =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_INVOICES_ADDRESSES_START });

      const response: AxiosResponse<fetchInvoicesAddressesResponseData> =
        await fetchInvoicesAddressesApi(id);

      const addresses = response.data.data.invoiceAddress;

      dispatch({
        type: types.FETCH_INVOICES_ADDRESSES_SUCCESS,
        payload: {
          addresses,
          isComplex: response.data.data.complexAddress,
        },
      });

      if (!response.data.data.complexAddress) {
        dispatch(fetchLatestInvoice());
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.FETCH_INVOICES_ADDRESSES_FAILURE });
    }
  };

export const fetchInvoicesHistory =
  (
    page: number = 1,
    addressesId: string[] = [],
    startDate: Date = new Date(),
    endDate: Date = new Date(),
    sort: string = ""
  ) =>
  async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_INVOICES_HISTORY_START });

      const response: AxiosResponse<fetchInvoicesHistoryResponseData> =
        await fetchInvoicesHistoryApi(id, {
          skip: (page - 1) * PAGINATION.INVOICES_PER_PAGE,
          take: PAGINATION.INVOICES_PER_PAGE,
          addressId: [...addressesId].join(),
          startDate: startDate.toISOString(),
          endDate: endOfDay(endDate).toISOString(),
          sort,
        } as fetchInvoicesHistoryRequestParams);

      dispatch({
        type: types.FETCH_INVOICES_HISTORY_SUCCESS,
        payload: {
          invoices: response?.data?.data,
          currentPageInvoices: page,
          totalResultsInvoices: response?.data?.total,
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.FETCH_INVOICES_HISTORY_FAILURE });
    }
  };

export const fetchInvoiceReception =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_INVOICE_RECEPTION_START });

      const response: AxiosResponse<fetchInvoiceReceptionResponseData> =
        await fetchInvoiceReceptionApi(id);

      dispatch({
        type: types.FETCH_INVOICE_RECEPTION_SUCCESS,
        payload: response?.data?.data,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          dispatch(signOut());
          toastError({ message: "Non autorisé" });
        }
      }
      dispatch({ type: types.FETCH_INVOICE_RECEPTION_FAILURE });
    }
  };

export const downloadInvoicesPdf =
  (invoicesIds) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.DOWNLOAD_INVOICES_PDF_START });

      toastInfo({
        message: "Le téléchargement va démarrer, merci de patienter.",
      });
      const response = await downloadInvoicesPdfApi(id, {
        listOfPdfs: invoicesIds,
      });
      console.log("Download file!");
      downloadFromResp(response);

      dispatch({ type: types.DOWNLOAD_INVOICES_PDF_SUCCESS });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          /* dispatch(signOut()); */
          toastError({ message: "La facture n'est pas disponible, veuillez contacter le support si l'erreur persiste!" });
        } else {
          toastError({ message: "Une erreur est survenue lors du téléchargement de la facture." });
        }
      }
      dispatch({ type: types.DOWNLOAD_INVOICES_PDF_FAIURE });
    }
  };

export const allAddressesUnchecked = () => ({
  type: types.ALL_ADDRESSES_UNCHECKED,
});
