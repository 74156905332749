/* eslint-disable linebreak-style */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable consistent-return */
/* eslint-disable no-console */

import { useEffect, useState } from "react";
import { DownloadIcon, SearchIcon } from "../../../assets/icons";
import { PAGINATION } from "../../../common/constants/pagination";
import { SORTING } from "../../../common/constants/sorting";
import {
  dateOnly,
  formatDate,
  subtractDate,
  subtractDateBy,
} from "../../../common/helpers/dates";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import {
  Button,
  Col,
  Input,
  Row,
  Separator,
  StatusLabel,
  Table,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import styles from "./InvoicesHistory.module.scss";
import {
  allAddressesUnchecked,
  downloadInvoicesPdf,
  fetchInvoicesHistory,
} from "./service/invoices.actions";
import {
  getCurrentSort,
  limitAddresses,
  searchAddresses,
} from "./service/invoices.helpers";
import {
  currentPageInvoicesSelector,
  invoicesAddressesSelector,
  invoicesIsComplexSelector,
  invoicesSelector,
  isFetchingInvoicesHistorySelector,
  totalResultsInvoicesSelector,
} from "./service/invoices.selectors";

const InvoicesHistoryComplex = () => {
  const dispatch = useAppDispatch();
  const invoices = useAppSelector(invoicesSelector);
  const isFetchingInvoices = useAppSelector(isFetchingInvoicesHistorySelector);
  const totalResultsInvoices = useAppSelector(totalResultsInvoicesSelector);
  const currentPageInvoices = useAppSelector(currentPageInvoicesSelector);
  const isComplex = useAppSelector(invoicesIsComplexSelector);
  const [dateRanges, setDateRanges] = useState([
    subtractDateBy(dateOnly(), "year", 1),
    dateOnly(),
  ]);
  const [checkedAddresses, setCheckedAddresses] = useState([
    { addressId: "ALL", checked: false },
  ]);
  const [checkedInvoices, setCheckedInvoices] = useState([
    { invoiceId: "ALL", invoiceNumber: "", checked: false },
  ]);
  const [currentSort, setCurrentSort] = useState("");
  const [dateRangesError, setDateRangesError] = useState("");
  const [addressesSearchValue, setAddressesSearchValue] = useState("");
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const [totalAddresses, setTotalAddresses] = useState(
    useAppSelector(invoicesAddressesSelector)
  );
  const addresses = totalAddresses.slice(
    0,
    showAllAddresses ? undefined : PAGINATION.ADDRESS_COUNTERS_PER_PAGE
  );
  const addressesIds = checkedAddresses
    .filter((address) => address.checked && address.addressId !== "ALL")
    .map((address) => address.addressId);

  // when addresses are fetched, set new state for addresses checkboxes
  useEffect(() => {
    if (checkedAddresses.length > 1) {
      return;
    }

    setCheckedAddresses([
      { addressId: "ALL", checked: false },
      ...totalAddresses.map((address) => ({ ...address, checked: false })),
    ]);
  }, [addresses]);

  // when state for addresses checkboxes changes, or new date is chosen, fetch new invoices
  useEffect(() => {
    if (addressesIds.length > 0) {
      if (dateRanges[0] <= dateRanges[1]) {
        dispatch(
          fetchInvoicesHistory(1, addressesIds, dateRanges[0], dateRanges[1])
        );
      }
    } else {
      setCurrentSort("");
      dispatch(allAddressesUnchecked());
    }
    if (dateRanges[0] <= dateRanges[1]) {
      setDateRangesError("");
    } else {
      setDateRangesError(
        "La date de début doit être antérieure à la date de fin"
      );
    }
  }, [checkedAddresses, dateRanges]);

  // when invoices are fetched, set new state for invoices checkboxe
  useEffect(() => {
    setCheckedInvoices([
      { invoiceId: "ALL", invoiceNumber: "", checked: false },
      ...invoices.map((invoice) => ({
        invoiceId: invoice.invoiceId,
        invoiceNumber: invoice.invoiceNumber,
        checked: false,
      })),
    ]);
  }, [invoices]);

  const handleSortChange = ({ dataIndex, sort }) => {
    if (dataIndex === "date") {
      const newSort = sort === "ASC" ? SORTING.DATE_ASC : SORTING.DATE_DESC;
      setCurrentSort(newSort);
      dispatch(
        fetchInvoicesHistory(
          currentPageInvoices,
          addressesIds,
          dateRanges[0],
          dateRanges[1],
          newSort
        )
      );
    } else {
      const newSort = sort === "ASC" ? SORTING.AMOUNT_ASC : SORTING.AMOUNT_DESC;
      setCurrentSort(newSort);
      dispatch(
        fetchInvoicesHistory(
          currentPageInvoices,
          addressesIds,
          dateRanges[0],
          dateRanges[1],
          newSort
        )
      );
    }
    trackClickHandler();
  };

  const downloadSelectedIds = () => {
    const selectedIds = checkedInvoices
      .slice(1)
      .reduce((checked: { id: string; number: string }[], invoice) => {
        if (invoice.checked) {
          checked.push({
            id: invoice.invoiceId,
            number: invoice.invoiceNumber,
          });
        }
        return checked;
      }, []);
    if (selectedIds && selectedIds.length) {
      dispatch(downloadInvoicesPdf(selectedIds));
    }
  };

  // function formatAddressesData(arg0: any):
  //   | {
  //       [key: string]: import("react").ReactNode;
  //       key: string | number;
  //       checked?: boolean | undefined;
  //     }[]
  //   | undefined {
  //   throw new Error("Function not implemented.");
  // }
  // function getDataForAddressesTable(arg0: any): any {
  //   throw new Error("Function not implemented.");
  // }

  const formatAddressesData = (addressesData) => {
    try {
      // console.log("formatAddressData:", addressesData);
      // console.log("formatAddressDatalimit:", limitAddresses(addressesData));
      const newAddress2 = addressesData.map((address) => ({
        ...address,
        checked: checkedAddresses.find(
          (addressItem) => addressItem.addressId === address.key
        )?.checked,
      }));
      return newAddress2;
    } catch {
      console.log(Error);
      console.log(addressesData);
    }
  };

  const getDataForAddressesTable = (addressesData) => {
    // console.log("showAll:", showAllAddresses);
    // console.log("getAddressData:", addressesData);
    // console.log("getAddressDataLimit:", limitAddresses(addressesData));
    return addressesData.map((address) => ({
      key: address.addressId,
      address: address.address,
      pointTheMeasure: address.pointTheMeasure && (
        <div>
          <div>
            {address.pointTheMeasure?.slice(0, 20)}
            <br />
            {address.pointTheMeasure?.slice(20)}
          </div>
        </div>
      ),
      // installationNumber: (
      //   <div>
      //     {address.pointTheMeasure?.slice(0, 20)}
      //     <br />
      //     {address.pointTheMeasure?.slice(20)}
      //   </div>
      // ),
      counters: address.listOfCounters?.map((counter) => (
        <div className={styles.counter} key={counter}>
          {counter}
        </div>
      )),
      // counters: address.listOfCounters?.map((counter) => (
      //   <div key={counter}>{counter}</div>
      // )),
      checked: checkedAddresses.find(
        (addressItem) => addressItem.addressId === address.addressId
      )?.checked,
    }));
  };

  return (
    <Col hasPageContentOffset span={12}>
      <Row justify="start">
        <Col lg={6} md={8} span={12}>
          <Input
            disabled={addressesIds.length > 0}
            placeholder="Sélectionner vos lieux de consommation"
            prefix={<SearchIcon />}
            onChange={(event) => {
              setAddressesSearchValue(event.target.value);
            }}
            onFocus={trackClickHandler}
          />
        </Col>
      </Row>
      <Separator />
      <Table
        checkableFirstColumn
        checkableRows
        columns={[
          {
            dataIndex: "addresses",
            title: "Adresses",
            checked: checkedAddresses[0].checked,
          },
          { dataIndex: "pointTheMeasure", title: "Numéro de point de mesure" },
          { dataIndex: "counters", title: "Compteur" },
        ]}
        data={
          formatAddressesData(
            showAllAddresses
              ? getDataForAddressesTable(
                  searchAddresses(totalAddresses, addressesSearchValue)
                )
              : getDataForAddressesTable(
                  limitAddresses(
                    searchAddresses(totalAddresses, addressesSearchValue)
                  )
                )
          )
        }
        hasShowAllButton={
          !showAllAddresses &&
          totalAddresses.length !== limitAddresses(totalAddresses).length
        }
        id="complexInvoicesAddressTable"
        noDataMessage="Aucune adresse à présenter"
        totalResults={
          totalAddresses.length - PAGINATION.ADDRESS_COUNTERS_PER_PAGE
        }
        onColumnChecked={(e) => {
          trackClickHandler();
          setCheckedAddresses(
            checkedAddresses.map((address) => ({
              ...address,
              checked: e.target.checked,
            }))
          );
        }}
        onRowChecked={(e) => {
          trackClickHandler();
          setCheckedAddresses([
            { addressId: "ALL", checked: false },
            ...checkedAddresses
              .map((address) => ({
                ...address,
                checked:
                  +e.target.name === +address.addressId
                    ? e.target.checked
                    : address.checked,
              }))
              .slice(1),
          ]);
        }}
        onShowAllResults={() => {
          setShowAllAddresses(true);
        }}
      />
      <Separator size="large" />
      <Row align="start" className={styles.datePickers} justify="start">
        <Col
          hasPageContentRightOffset
          className={styles.datePicker}
          lg={2}
          md={3}
          span={5}
        >
          <Input
            // defaultValue={subtractDate(new Date(), 'input', 'year', 1)}
            defaultValue={formatDate(dateRanges[0], "input")}
            error={dateRangesError}
            label="Période du :"
            min={subtractDate(new Date(), "input", "year", 2)}
            type="date"
            onChange={(event) => {
              trackClickHandler();
              setDateRanges([new Date(event.target.value), dateRanges[1]]);
            }}
          />
        </Col>
        <Col
          hasPageContentRightOffset
          className={styles.datePicker}
          lg={2}
          md={3}
          span={5}
        >
          <Input
            // defaultValue={formatDate(new Date(), 'input')}
            defaultValue={formatDate(dateRanges[1], "input")}
            label="au :"
            type="date"
            onChange={(event) => {
              trackClickHandler();
              setDateRanges([dateRanges[0], new Date(event.target.value)]);
            }}
          />
        </Col>
        <Button
          className={styles.exportAsPdfLink}
          suffix={<DownloadIcon className={styles.downloadIcon} />}
          onClick={() => {
            trackClickHandler();
            downloadSelectedIds();
          }}
        >
          Télécharger les factures sélectionnées
        </Button>
      </Row>
      <Separator />
      <Table
        checkableFirstColumn
        checkableRows
        pagination
        columns={[
          {
            dataIndex: "address",
            title: "Adresse",
            checked: checkedInvoices[0].checked,
          },
          {
            dataIndex: "date",
            title: "Date",
            sort: getCurrentSort("date", currentSort),
          },
          { dataIndex: "invoiceNumber", title: "Numéro de facture" },
          // { dataIndex: 'invoiceType', title: 'Type de facture' },
          {
            dataIndex: "amount",
            title: "Montant (CHF)",
            sort: getCurrentSort("amount", currentSort),
          },
          { dataIndex: "status", title: "Statut" },
        ]}
        currentPage={currentPageInvoices}
        data={
          checkedAddresses.length > 0 || !isComplex
            ? invoices.map((invoice) => ({
                key: invoice.invoiceId,
                address: invoice.address || "Pas d'adresse pour cette facture",
                date: formatDate(new Date(invoice.dateOfInvoice), "dd.mm.yyyy"),
                invoiceNumber: invoice.invoiceNumber,
                // invoiceType: invoice.typeOfFacture,
                amount: invoice.amount,
                checked: checkedInvoices.find(
                  (invoiceItem) => invoiceItem.invoiceId === invoice.invoiceId
                )?.checked,
                status: (
                  <Row justify="space-between" wrap={false}>
                    <StatusLabel status={invoice.status} />
                    <DownloadIcon
                      className={styles.downloadIcon}
                      onClick={() => {
                        trackClickHandler();
                        dispatch(
                          downloadInvoicesPdf([
                            {
                              id: invoice.invoiceId,
                              number: invoice.invoiceNumber,
                            },
                          ])
                        );
                      }}
                    />
                  </Row>
                ),
              }))
            : []
        }
        id="complexInvoicesHistoryTable"
        loading={isFetchingInvoices}
        noDataMessage="Veuillez sélectionner les lieux de consommation à afficher"
        totalPages={Math.ceil(
          totalResultsInvoices / PAGINATION.INVOICES_PER_PAGE
        )}
        onColumnChecked={(e) => {
          trackClickHandler();
          setCheckedInvoices(
            checkedInvoices.map((invoiceItem) => ({
              ...invoiceItem,
              checked: e.target.checked,
            }))
          );
        }}
        onPageChange={(page) => {
          trackClickHandler();
          dispatch(
            fetchInvoicesHistory(
              page,
              addressesIds,
              dateRanges[0],
              dateRanges[1],
              currentSort
            )
          );
        }}
        onRowChecked={(e) => {
          trackClickHandler();
          setCheckedInvoices([
            { invoiceId: "ALL", invoiceNumber: "", checked: false },
            ...checkedInvoices
              .map((invoiceItem) => ({
                ...invoiceItem,
                checked:
                  +e.target.name === +invoiceItem.invoiceId
                    ? e.target.checked
                    : invoiceItem.checked,
              }))
              .slice(1),
          ]);
        }}
        onSortChange={handleSortChange}
      />
    </Col>
  );
};

export default InvoicesHistoryComplex;
