/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { PAGES } from "../../../common/constants/common";
import { formatDate } from "../../../common/helpers/dates";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import trackHistory from "../../../common/helpers/trackHistory";
import { Col, Dropdown, Link, Loader, Radio, Row, Separator, Text, Title } from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchInvoiceReceptionMode, fetchInvoicesAddresses as fetchMyProfileAddresses } from "../../auth/auth.actions";
import {
  invoicesAddressesSelector as myProfileAddressesSelector,
  isFetchingAuthSelector as isFetchingMyProfileAddressesSelector,
  isFetchingReceptionModeSelector,
  receptionModeSelector,
} from "../../auth/auth.selectors";
import styles from "./Invoices.module.scss";
import BillsHistoryComplex from "./InvoicesHistoryComplex";
import BillsHistorySimple from "./InvoicesHistorySimple";
import {
  fetchInvoiceReception,
  fetchInvoicesAddresses,
  fetchLatestInvoice,
} from "./service/invoices.actions";
import {
  invoiceReceptionSelector,
  invoicesAddressesSelector,
  invoicesIsComplexSelector,
  isFetchingInvoiceReceptionSelector,
  isFetchingInvoicesAddressesSelector,
  isFetchingLatestInvoiceSelector,
  latestInvoiceSelector,
} from "./service/invoices.selectors";

const cx = classNames.bind(styles);

const Invoices = () => {
  const location = window.location.href;
  const isComplex = useAppSelector(invoicesIsComplexSelector);
  const isFetchingAddresses = useAppSelector(
    isFetchingInvoicesAddressesSelector
  );
  const isFetchingMyProfileAddresses = useAppSelector(
    isFetchingMyProfileAddressesSelector
  );
  const isFetchingLatestInvoice = useAppSelector(
    isFetchingLatestInvoiceSelector
  );
  const isFetchingInvoiceReception = useAppSelector(
    isFetchingInvoiceReceptionSelector
  );
  const latestInvoice = useAppSelector(latestInvoiceSelector);
  const invoiceReception = useAppSelector(invoiceReceptionSelector);
  const dispatch = useAppDispatch();
  const invoicesAddresses = useAppSelector(invoicesAddressesSelector);
  const myProfileAddresses = useAppSelector(myProfileAddressesSelector);
  const receptionMode = useAppSelector(receptionModeSelector);

  const isFetching = useAppSelector(isFetchingReceptionModeSelector);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const onAddressChange = (e) => {
    const newSelectedAddress = myProfileAddresses?.find(
      (address) => address.address === e.target.value
    );
    if (newSelectedAddress) {
      setSelectedAddress(newSelectedAddress);
      dispatch(fetchInvoiceReceptionMode(newSelectedAddress.objektId));
    }
  };

  useEffect(() => {
    dispatch(fetchMyProfileAddresses());
    dispatch(fetchInvoicesAddresses());
    dispatch(fetchInvoiceReception());
    dispatch(fetchLatestInvoice());
    trackHistory(sessionStorage.getItem("clicks") ?? 0, location);
    sessionStorage.removeItem("clicks");
  }, []);

  if (!invoicesAddresses) {
    return <Loader type="content" />;
  }

  if (
    isFetchingAddresses ||
    isFetchingMyProfileAddresses ||
    isFetchingLatestInvoice ||
    isFetchingInvoiceReception
  ) {
    return <Loader />;
  }

  return (
    <>
      <Row align="start" className={styles.myInvoices} direction="column">
        <Col>
          <Title>Mes factures</Title>
        </Col>
        <Separator />
        <Col span={12}>
          <Row
            align="stretch"
            className={styles.myInvoicesInfo}
            justify={isComplex ? "start" : "space-between"}
            wrap={false}
          >
            {/* {!isComplex && ( */}
            <Col
              isContentBox
              className={styles.lastInvoiceBox}
              md={4}
              span={12}
            >
              <Title isBoxTitle align="center" level={3}>
                Dernière facture
              </Title>
              {latestInvoice?.amount && (
                <>
                  <Title align="center" className={styles.lastInvoicePrice}>
                    <>
                      {latestInvoice?.amount}{" "}
                      <span className={styles.currency}>CHF</span>
                    </>
                  </Title>
                  <Text align="center">
                    Facture pour la dernière période{" "}
                    {formatDate(new Date(latestInvoice?.dueDate), "dd.mm.yyyy")}
                  </Text>
                </>
              )}
              {!latestInvoice?.amount && (
                <Text align="center">
                  Vous n’avez pas encore de facture disponible.
                </Text>
              )}
            </Col>
            {/* )} */}
            {/* {isComplex && (
              <Col
                isContentBox className={styles.lastInvoiceBox} md={4}
                span={12}
            >
                <Title isBoxTitle align="center" level={3}>Dernière facture</Title>
                <Separator size="large" />
                {latestInvoice?.amount &&
                <>
                  <Text isLight align="center">CHF</Text>
                  <Title align="center" className={styles.lastInvoicePrice}>{latestInvoice?.amount}</Title>
                  <Text align="center">
                    Facture pour la dernière période {formatDate(new Date(latestInvoice?.dueDate), 'dd.mm.yyyy')}
                  </Text>
                </>}
                {latestInvoice?.amount && <Text align="center">Vous n’avez pas encore de facture disponible.</Text>}
              </Col>
            )} */}
            <Col
              isContentBox
              className={cx({
                [styles.paymentMethodsComplex]: isComplex,
                [styles.paymentMethodsSimple]: !isComplex,
              })}
              md={isComplex ? 4 : 5}
              span={12}
            >
              <Title isBoxTitle level={3}>
                Mode de facturation
              </Title>
              <Separator />
              <Dropdown
                className={styles.receptionDropdown}
                id="invoiceReceptionDropdown"
                name="invoiceReceptionDropdown"
                options={
                  myProfileAddresses?.map((address) => address.address) || []
                }
                placeholder="Sélectionner l'adresse"
                onChange={onAddressChange}
              />
              {isFetching && <Loader smallSize type="content" />}
              {receptionMode && selectedAddress && !isFetching && (
                <>
                  <Separator />
                  <Text>Mode de réception de vos factures :</Text>
                  <Separator size="small" />
                  <Row
                    className={styles.receptionDropdown}
                    justify="space-evenly"
                  >
                    <Radio
                      disabled
                      className={styles.receptionModeRadio}
                      defaultChecked={receptionMode?.email}
                      id="email"
                      name="invoiceReception"
                      onChange={trackClickHandler}
                    >
                      Email
                    </Radio>
                    <Radio
                      disabled
                      className={styles.receptionModeRadio}
                      defaultChecked={receptionMode?.courrier}
                      id="courrier"
                      name="invoiceReception"
                      onChange={trackClickHandler}
                    >
                      Courrier
                    </Radio>
                    <Radio
                      disabled
                      className={styles.receptionModeRadio}
                      defaultChecked={receptionMode?.ebanking}
                      id="ebanking"
                      name="invoiceReception"
                      onChange={trackClickHandler}
                    >
                      eBill
                    </Radio>
                  </Row>
                </>
              )}
              <Separator size="large" />
              <Link
                className={styles.contactAndFormsLink}
                to={PAGES.CONTACT_AND_FORMS}
                onClick={trackClickHandler}
              >
                Modifier mon mode de facturation
              </Link>
            </Col>
            <Col
              className={styles.informations}
              md={isComplex ? 4 : 5}
              span={12}
            >
              <Title level={3}>Informations</Title>
              <Separator />
              <Link
                isLight
                href="https://www.sie.ch/electricite/tarifs-et-liste-de-prix-267"
                onClick={trackClickHandler}
              >
                Nos tarifs et liste de prix
              </Link>
              <Separator size="small" />
              <Link
                isLight
                href="https://www.sie.ch/"
                onClick={trackClickHandler}
              >
                Nos offres énergies
              </Link>
              <Title align="center" level={3}>
                Modes de facturation possibles
              </Title>
              <Text>Email, courrier et eBill.</Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Separator size="large" />
      <Row ignorePageContentPadding align="start" direction="column">
        <Col hasPageContentOffset>
          <Title level={2}>Mon historique de factures</Title>
        </Col>
        <Separator />
        {isComplex ? <BillsHistoryComplex /> : <BillsHistorySimple />}
      </Row>
    </>
  );
};

export default Invoices;
