/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { ReactNode } from "react";
import classNames from "classnames/bind";
import styles from "./Text.module.scss";

const cx = classNames.bind(styles);

type TextProps = {
  size?: "small" | "large";
  isBold?: boolean;
  isLight?: boolean;
  align?: "left" | "right" | "center";
  className?: string;
  children?: ReactNode;
};

const Text = ({
  size = "small",
  isBold = false,
  isLight = false,
  align = "left",
  className = "",
  children,
}: TextProps) => (
  <p
    className={cx(
      styles[size],
      styles[align],
      {
        [styles.bold]: isBold,
        [styles.light]: isLight,
      },
      className
    )}
  >
    {children}
  </p>
);

export default Text;
