/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
export const dateOnly = () => {
  const newDate = new Date();
  const timezoneOffset = newDate.getTimezoneOffset() * 60000;
  newDate.setHours(0, 0, 0, 0);
  newDate.setTime(newDate.getTime() - timezoneOffset);
  return newDate;
};

export const endOfDay = (date: Date) => {
  const newDate = new Date(date);
  const timezoneOffset = newDate.getTimezoneOffset() * 60000;
  newDate.setHours(23, 59, 59, 999);
  newDate.setTime(newDate.getTime() - timezoneOffset);
  return newDate;
};

export const subtractDateBy = (
  date: Date,
  timePeriod: "day" | "month" | "year",
  amount: number
) => {
  if (timePeriod === "day") {
    date.setDate(date.getDate() - amount);
  }
  if (timePeriod === "month") {
    date.setMonth(date.getMonth() - amount);
  }
  if (timePeriod === "year") {
    date.setFullYear(date.getFullYear() - amount);
  }

  // set min date value to be Jan. 1, 2023
  if (date.getFullYear() < 2023) {
    date.setFullYear(2023, 0, 1);
  }

  return date;
};

export const addDateBy = (
  date: Date,
  timePeriod: "day" | "month" | "year",
  amount: number
) => {
  if (timePeriod === "day") {
    date.setDate(date.getDate() + amount);
  }
  if (timePeriod === "month") {
    date.setMonth(date.getMonth() + amount);
  }
  if (timePeriod === "year") {
    date.setFullYear(date.getFullYear() + amount);
  }
  return date;
};

export const formatDate = (
  date: Date,
  format: "input" | "dd.mm.yyyy" | "dd.mm.yyyy hh.mm" | "dd-mm-yyyy-hh-mm-ss"
) => {
  if (format === "input") {
    return `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
  }
  if (format === "dd.mm.yyyy") {
    return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }.${date.getFullYear()}`;
  }
  if (format === "dd.mm.yyyy hh.mm") {
    return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }.${date.getFullYear()} ${date.getHours()}.${date.getMinutes()}`;
  }
  if (format === "dd-mm-yyyy-hh-mm-ss") {
    return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getFullYear()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
  }
  return "";
};

export const subtractDate = (
  date: Date,
  format: "input",
  timePeriod: "day" | "month" | "year",
  amount: number
) => {
  subtractDateBy(date, timePeriod, amount);

  if (format === "input") {
    return `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
  }
  return "";
};

export const addDate = (
  date: Date,
  format: "input",
  timePeriod: "day" | "month" | "year",
  amount: number
) => {
  addDateBy(date, timePeriod, amount);

  if (format === "input") {
    return `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
  }
  return "";
};
