/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { ReactNode } from "react";
import classNames from "classnames/bind";
import styles from "./Row.module.scss";

const cx = classNames.bind(styles);

type directionTypes = "row" | "column" | "row-reverse" | "column-reverse";
type justifyTypes =
  | "center"
  | "start"
  | "end"
  | "space-between"
  | "space-evenly"
  | "space-around";
type alignTypes = "center" | "start" | "end" | "stretch" | "baseline";

type RowProps = {
  direction?: directionTypes;
  justify?: justifyTypes;
  align?: alignTypes;
  page?: boolean;
  wrap?: boolean;
  wrapForMobile?: boolean;
  ignorePageContentPadding?: boolean;
  className?: string;
  children?: ReactNode;
};

const Row = ({
  direction = "row",
  justify = "center",
  align = "center",
  wrap = true,
  wrapForMobile = false,
  page = false,
  ignorePageContentPadding = false,
  className = "",
  children,
}: RowProps) => (
  <div
    className={cx(
      styles.row,
      styles[`direction-${direction}`],
      styles[`justify-${justify}`],
      styles[`align-${align}`],
      {
        [styles.noWrap]: !wrap,
        [styles.pageWithFlexWrap]: page && wrap,
        [styles.pageWithoutFlexWrap]: page && !wrap,
        [styles.ignorePageContentPadding]: ignorePageContentPadding,
        [styles.wrapForMobile]: wrapForMobile,
      },
      className
    )}
  >
    {children}
  </div>
);

export default Row;
