/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
import { useEffect, useState } from "react";
import { DownloadIcon } from "../../../assets/icons";
import { PAGINATION } from "../../../common/constants/pagination";
import {
  dateOnly,
  formatDate,
  subtractDate,
  subtractDateBy,
} from "../../../common/helpers/dates";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import {
  Button,
  Col,
  Input,
  Row,
  Separator,
  StatusLabel,
  Table,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import styles from "./InvoicesHistory.module.scss";
import {
  downloadInvoicesPdf,
  fetchInvoicesHistory,
} from "./service/invoices.actions";
import {
  currentPageInvoicesSelector,
  invoicesAddressesSelector,
  invoicesSelector,
  isFetchingInvoicesHistorySelector,
  totalResultsInvoicesSelector,
} from "./service/invoices.selectors";

const InvoicesHistorySimple = () => {
  const dispatch = useAppDispatch();
  const invoices = useAppSelector(invoicesSelector);
  const addresses = useAppSelector(invoicesAddressesSelector);
  const currentPage = useAppSelector(currentPageInvoicesSelector);
  const totalResults = useAppSelector(totalResultsInvoicesSelector);
  const isFetchingInvoices = useAppSelector(isFetchingInvoicesHistorySelector);
  const [checkedInvoices, setCheckedInvoices] = useState([
    { invoiceId: "ALL", invoiceNumber: "", checked: false },
  ]);
  const [dateRanges, setDateRanges] = useState([
    subtractDateBy(dateOnly(), "year", 1),
    dateOnly(),
  ]);
  const [dateRangesError, setDateRangesError] = useState("");
  const addressesIds = addresses.map((address) => address.addressId);

  useEffect(() => {
    if (addressesIds.length > 0) {
      if (dateRanges[0] <= dateRanges[1]) {
        dispatch(
          fetchInvoicesHistory(1, addressesIds, dateRanges[0], dateRanges[1])
        );
        setDateRangesError("");
      } else {
        setDateRangesError(
          "La date de début doit être antérieure à la date de fin"
        );
      }
    }
  }, [addresses, dateRanges]);

  useEffect(() => {
    setCheckedInvoices([
      { invoiceId: "ALL", invoiceNumber: "", checked: false },
      ...invoices.map((invoice) => ({
        invoiceId: invoice.invoiceId,
        invoiceNumber: invoice.invoiceNumber,
        checked: false,
      })),
    ]);
  }, [invoices]);

  const downloadSelectedIds = () => {
    const selectedIds = checkedInvoices
      .slice(1)
      .reduce((checked: { id: string; number: string }[], invoice) => {
        if (invoice.checked) {
          checked.push({
            id: invoice.invoiceId,
            number: invoice.invoiceNumber,
          });
        }
        return checked;
      }, []);
    if (selectedIds && selectedIds.length) {
      dispatch(downloadInvoicesPdf(selectedIds));
    }
  };

  return (
    <Col hasPageContentOffset span={12}>
      <Row align="start" justify="start">
        <Col
          hasPageContentRightOffset
          className={styles.datePicker}
          lg={2}
          md={3}
          span={12}
        >
          <Input
            defaultValue={formatDate(dateRanges[0], "input")}
            error={dateRangesError}
            label="Période du :"
            min={subtractDate(new Date(), "input", "year", 2)}
            type="date"
            onChange={(event) => {
              trackClickHandler();
              setDateRanges([new Date(event.target.value), dateRanges[1]]);
            }}
          />
        </Col>
        <Col
          hasPageContentRightOffset
          className={styles.datePicker}
          lg={2}
          md={3}
          span={12}
        >
          <Input
            defaultValue={formatDate(dateRanges[1], "input")}
            label="au :"
            type="date"
            onChange={(event) => {
              trackClickHandler();
              setDateRanges([dateRanges[0], new Date(event.target.value)]);
            }}
          />
        </Col>
        <Button
          className={styles.exportAsPdfLink}
          suffix={<DownloadIcon className={styles.downloadIcon} />}
          onClick={() => {
            trackClickHandler();
            downloadSelectedIds();
          }}
        >
          Télécharger les factures sélectionnées
        </Button>
      </Row>
      <Separator />
      <Table
        checkableFirstColumn
        checkableRows
        pagination
        columns={[
          {
            dataIndex: "date",
            title: "Date",
            sort: "ASC",
            checked: checkedInvoices[0].checked,
          },
          { dataIndex: "invoiceNumber", title: "Numéro de facture" },
          // { dataIndex: 'invoiceType', title: 'Type de facture' },
          { dataIndex: "amount", title: "Montant (CHF)", sort: "ASC" },
          { dataIndex: "status", title: "Statut" },
        ]}
        currentPage={currentPage}
        data={invoices.map((invoice) => ({
          key: invoice.invoiceId,
          date: formatDate(new Date(invoice.dateOfInvoice), "dd.mm.yyyy"),
          invoiceNumber: invoice.invoiceNumber,
          // invoiceType: invoice.typeOfFacture,
          amount: invoice.amount,
          checked: checkedInvoices.find(
            (invoiceItem) => invoiceItem.invoiceId === invoice.invoiceId
          )?.checked,
          status: (
            <Row justify="space-between" wrap={false}>
              <StatusLabel status={invoice.status} />
              <DownloadIcon
                className={styles.downloadIcon}
                onClick={() => {
                  trackClickHandler();
                  dispatch(
                    downloadInvoicesPdf([
                      {
                        id: invoice.invoiceId,
                        number: invoice.invoiceNumber,
                      },
                    ])
                  );
                }}
              />
            </Row>
          ),
        }))}
        id="simpleInvoicesHistoryTable"
        loading={isFetchingInvoices}
        totalPages={Math.ceil(totalResults / PAGINATION.INVOICES_PER_PAGE)}
        onColumnChecked={(e) => {
          trackClickHandler();
          setCheckedInvoices(
            checkedInvoices.map((invoiceItem) => ({
              ...invoiceItem,
              checked: e.target.checked,
            }))
          );
        }}
        onPageChange={(page) => {
          trackClickHandler();
          dispatch(
            fetchInvoicesHistory(
              page,
              addressesIds,
              dateRanges[0],
              dateRanges[1]
            )
          );
        }}
        onRowChecked={(e) => {
          trackClickHandler();
          setCheckedInvoices([
            { invoiceId: "ALL", invoiceNumber: "", checked: false },
            ...checkedInvoices
              .map((invoiceItem) => ({
                ...invoiceItem,
                checked:
                  +e.target.name === +invoiceItem.invoiceId
                    ? e.target.checked
                    : invoiceItem.checked,
              }))
              .slice(1),
          ]);
        }}
      />
    </Col>
  );
};

export default InvoicesHistorySimple;
