/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

const trackHistory = (click, location) =>
  (window as any).dataLayer.push({
    event: "pageHistory",
    data: location,
    clicks: +click,
  });

export default trackHistory;
