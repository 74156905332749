/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import React from "react";
import { SmallArrowIcon } from "../../../assets/icons";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import styles from "./DropdownWithSearch.module.scss";

const cx = classNames.bind(styles);

interface IDropdownProps {
  className?: string;
  defaultValue: string;
  disabled?: boolean;
  id: string;
  label?: string;
  name: string;
  onChange?: (code: { postalCodeId: string; value: string } | null) => void;
  optionLabel: string;
  options: { postalCodeId: string; value: string }[];
  optionValue: string;
  placeholder?: string;
  readOnly?: boolean;
}

const DropdownWithSearch = React.forwardRef<HTMLInputElement, IDropdownProps>(
  (
    {
      className,
      defaultValue,
      disabled = false,
      id,
      label,
      name,
      onChange = () => {},
      optionLabel,
      options,
      optionValue,
      placeholder,
      readOnly = false,
    }: IDropdownProps,
    forwardedRef
  ) => {
    const onBlur = (evt) => {
      evt.preventDefault();
      if (!readOnly && !disabled) {
        const selected = options.find(
          (option) => option.value === evt.target.value
        );
        if (selected) {
          onChange(selected[optionValue]);
        } else {
          const { target } = evt;
          target.value = "";
        }
      }
    };
    let debouncer: any = null;
    const onValueChange = (evt) => {
      evt.preventDefault();
      const newValue = evt.target.value;
      clearTimeout(debouncer);
      debouncer = setTimeout(() => {
        const selected = options.find(
          (option) => option[optionLabel] === newValue
        );
        if (selected) {
          onChange(selected[optionValue]);
        } else {
          onChange(null);
        }
      }, 400);
    };

    return (
      <div
        className={cx(
          styles.inputWrapper,
          disabled && styles.inputDisabled,
          className
        )}
      >
        {label && <div className={cx(styles.inputLabel)}>{label}</div>}
        <input
          className={cx(
            styles.input,
            {
              [styles.inputDisabled]: disabled && !readOnly,
              [styles.inputReadOnly]: readOnly,
            },
            className
          )}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          list="idlist"
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={forwardedRef}
          onBlur={(e) => {
            onBlur(e);
            trackClickHandler();
          }}
          onChange={(e) => {
            onValueChange(e);
            trackClickHandler();
          }}
        />
        <datalist id="idlist">
          {options.map((option) => (
            <option
              className={styles.dropdownOption}
              key={option[optionValue]}
              value={option[optionLabel]}
            >
              {option[optionLabel]}
            </option>
          ))}
        </datalist>
      </div>
    );
  }
);

export default DropdownWithSearch;
