/* eslint-disable linebreak-style */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import axios, { AxiosResponse } from 'axios';
import { PAGES, SESSION_STORAGE } from '../../common/constants/common';
import { AppDispatch } from '../../redux/store';
import * as actionTypes from './admin.actionTypes';
import { history, toastSuccess, toastError } from '../../services';
import {
  checkAdminConnectionApi,
  deleteNewsletterApi,
  deleteUserApi,
  extractNewsletterApi,
  impersonateUserApi,
  fetchUserApi,
  resendVerificationEmailApi,
} from '../../api/admin.api';
import {
  deleteUserRequestData,
  impersonateUserRequestData,
  impersonateUserResponseData,
  retrieveUserRequestData,
  resendVerificationEmailRequestData,
} from './admin.types';
import { downloadCsvFile } from '../../common/helpers/helpers';
import { formatDate } from '../../common/helpers/dates';

export const checkAdminConnection = () => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch({ type: actionTypes.CHECK_ADMIN_CONNECTION_START });

    // await checkAdminConnectionApi();

    dispatch({ type: actionTypes.CHECK_ADMIN_CONNECTION_SUCCESS });
    history.push(PAGES.ADMIN_DASHBOARD);
  } catch (error) {
    dispatch({ type: actionTypes.CHECK_ADMIN_CONNECTION_FAILURE });
  }
};

export const impersonateUser = (
  { userId }: impersonateUserRequestData,
  setFieldError: Function,
  setCurrentLoader: Function,
) => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch({ type: actionTypes.IMPERSONATE_USER_START });

    const response: AxiosResponse<impersonateUserResponseData> = await impersonateUserApi(userId);

    dispatch({ type: actionTypes.IMPERSONATE_USER_SUCCESS });

    sessionStorage.setItem(SESSION_STORAGE.ACCESS_TOKEN, response.data.data.token);
    sessionStorage.setItem(SESSION_STORAGE.USER_ID, response.data.data.userId.toString());
    sessionStorage.setItem(SESSION_STORAGE.ROLE, response.data.data.role);

    history.push(PAGES.DASHBOARD);
  } catch (error) {
    dispatch({ type: actionTypes.IMPERSONATE_USER_FAILURE });
    setCurrentLoader(null);
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        setFieldError('userId', {
          type: 'incorrectUserId',
          message: 'ID utilisateur incorrect ou utilisateur non enregistré'
        });
      }
    }
  }
};

export const deleteUser = ({ userId }: deleteUserRequestData, setFieldError: Function, resetLoaderAndForm: Function) => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch({ type: actionTypes.DELETE_USER_START });

    await deleteUserApi(userId);

    dispatch({ type: actionTypes.DELETE_USER_SUCCESS });

    toastSuccess({
      message: 'Utilisateur supprimé avec succès'
    });
    resetLoaderAndForm();
  } catch (error) {
    dispatch({ type: actionTypes.DELETE_USER_FAILURE });
    resetLoaderAndForm();
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        setFieldError('userId', {
          type: 'incorrectUserId',
          message: 'ID utilisateur incorrect ou utilisateur non enregistré'
        });
      }
    }
  }
};

export const extractNewsletter = (
  setCurrentLoader: Function,
) => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch({ type: actionTypes.EXTRACT_NEWSLETTER_START });

    const response = await extractNewsletterApi();

    if (response?.data?.data?.length > 0) {
      const data = response.data.data.map(row => ({
        timeOfSubscription: row.timeOfSubscription,
        email: row.email,
        userId: row.userId
      }));
      const listOfDataRows: any = [];
      listOfDataRows.push(['Time of subscription', 'Email', 'User Id']);
      data.forEach(dataItem => listOfDataRows.push([
        formatDate(new Date(dataItem.timeOfSubscription), 'dd.mm.yyyy'), dataItem.email, dataItem.userId
      ]));
      downloadCsvFile(listOfDataRows, 'subscriptions.csv');
    }

    dispatch({ type: actionTypes.EXTRACT_NEWSLETTER_SUCCESS });
    setCurrentLoader(null);
  } catch (error) {
    dispatch({ type: actionTypes.EXTRACT_NEWSLETTER_FAILURE });
    setCurrentLoader(null);
  }
};

export const deleteNewsletter = (
  setCurrentLoader: Function,
) => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch({ type: actionTypes.DELETE_NEWSLETTER_START });

    await deleteNewsletterApi();

    dispatch({ type: actionTypes.DELETE_NEWSLETTER_SUCCESS });
    toastSuccess({ message: 'Liste d\'abonnés supprimée avec succès' });
    setCurrentLoader(null);
  } catch (error) {
    dispatch({ type: actionTypes.DELETE_NEWSLETTER_FAILURE });
    setCurrentLoader(null);
  }
};

export const retrieveUser = ({
  userId,
}: retrieveUserRequestData) => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch({ type: actionTypes.RETRIEVE_USER_START });

    const response = await fetchUserApi(userId);

    dispatch({ type: actionTypes.RETRIEVE_USER_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: actionTypes.RETRIEVE_USER_FAILURE });
    toastError({ message: 'ID utilisateur incorrect ou utilisateur non enregistré' });
  }
};

export const resendVerificationEmail = ({
  userId,
}: resendVerificationEmailRequestData) => async (
  dispatch: AppDispatch
) => {
  try {
    dispatch({ type: actionTypes.RESENDING_VERIFICATION_START });

    // const response: AxiosResponse<impersonateUserResponseData> = await impersonateUserApi(userId);
    // console.log('response', response);
    await resendVerificationEmailApi({ userId });

    toastSuccess({ message: 'Vérifiez votre boite de réception email, vous allez recevoir un message de confirmation dans quelques instants.' });
  } catch (error) {
    toastError({ message: 'ID utilisateur incorrect ou utilisateur non enregistré' });
  } finally {
    dispatch({ type: actionTypes.RESENDING_VERIFICATION_END });
  }
};
