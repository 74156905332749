/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { toast } from "react-toastify";
import { fetchUser, signOut } from "../../modules/auth/auth.actions";
import { userSelector } from "../../modules/auth/auth.selectors";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import PageLayout from "../organisms/pageLayout/PageLayout";
import { Loader } from "../ui";
import {
  MAXIMUM_USER_INACTIVITY_TIME,
  PAGES,
  ROLES,
  SESSION_STORAGE,
} from "../../common/constants/common";
import { changeAppPrimaryColor } from "../../common/helpers/helpers";
import { NAVIGATION_ITEMS } from "../organisms/navigation/navigation.config";
import { toastWarning } from "../../services/toasts/toasts";

type PrivateRouterProps = {
  page: JSX.Element;
};

const PrivateRouter = (props: PrivateRouterProps): JSX.Element => {
  const { page } = props;
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const { pathname } = useLocation();
  const isUserAdmin =
    sessionStorage.getItem(SESSION_STORAGE.ROLE) === ROLES.ADMIN;
  const [pageIsHiddenForUserScenario, setPageIsHiddenForUserScenario] =
    useState(false);
  const [userReachedInactivityMaximum, setUserReachedInactivityMaximum] =
    useState(false);

  const onPrompt = () => {
    setUserReachedInactivityMaximum(true);
    toastWarning({
      message: "Vous allez être déconnecté pour cause d'inactivité",
      timeout: 4300,
    });
  };

  const onIdle = () => {
    dispatch(signOut());
    toast.dismiss();
    toastWarning({
      message: "Vous avez été déconnecté pour cause d'inactivité",
      timeout: false,
    });
  };

  useIdleTimer({
    onPrompt,
    onIdle,
    timeout: MAXIMUM_USER_INACTIVITY_TIME,
    promptTimeout: 5000,
  });

  // fetch user data
  useEffect(() => {
    if (!user && !userReachedInactivityMaximum) {
      dispatch(
        fetchUser(sessionStorage.getItem(SESSION_STORAGE.USER_ID) || "")
      );
    }
  }, [user, dispatch]);

  // change primary color on route change and block access to page based on user scenario
  useEffect(() => {
    if (!user) {
      return;
    }
    changeAppPrimaryColor({ pathname });
    const currentNavigationItem = NAVIGATION_ITEMS.find(
      (navItem) => navItem.pathname === pathname
    );
    if (currentNavigationItem) {
      if (currentNavigationItem.hiddenForScenarios.includes(user?.scenario)) {
        setPageIsHiddenForUserScenario(true);
      } else {
        setPageIsHiddenForUserScenario(false);
      }
    } else {
      setPageIsHiddenForUserScenario(false);
    }
  }, [pathname, user]);

  if (pageIsHiddenForUserScenario) {
    return <Redirect to={PAGES.DASHBOARD} />;
  }

  return !user ? (
    <Loader type="auth" />
  ) : (
    <PageLayout content={page} type={isUserAdmin ? "admin" : "private"} />
  );
};

export default PrivateRouter;
