/* eslint-disable linebreak-style */
/* eslint-disable spaced-comment */
/* eslint-disable no-plusplus */
/* eslint-disable no-trailing-spaces */
/* eslint-disable keyword-spacing */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { PAGINATION } from "../../../../common/constants/pagination";

export const limitAddresses = (addresses) => {
  if (!addresses.length) {
    return [];
  }
  const limitedAddresses = [addresses[0]];

  let numberOfCounters = addresses[0].listOfCountersAndInstallations
    ? addresses[0].listOfCountersAndInstallations.map(
        (counterAndInstallation) => counterAndInstallation.counterId
      ).length
    : 0;
  addresses.slice(1).forEach((address) => {
    const numberOfCountersForAddress = address.listOfCountersAndInstallations
      ? address.listOfCountersAndInstallations.map(
          (counterAndInstallation) => counterAndInstallation.counterId
        ).length
      : 0;
    if (
      numberOfCountersForAddress + numberOfCounters <=
      PAGINATION.CONSUMPTION_ADDRESSES_PER_PAGE
    ) {
      limitedAddresses.push(address);
      numberOfCounters += numberOfCountersForAddress;
    }
  });

  return limitedAddresses.slice(0, 5);
};

export const searchAddresses = (addresses, searchValue) =>
  addresses.filter(
    (address) =>
      address.address.toLowerCase().includes(searchValue.toLowerCase()) ||
      address.listOfCountersAndInstallations
        ?.map((counterAndInstallation) => counterAndInstallation.counterId)
        .toString()
        .includes(searchValue) ||
      address.listOfCountersAndInstallations
        ?.map((counterAndInstallation) => counterAndInstallation.installationId)
        .toString()
        .includes(searchValue)
  );

export const getBarWidth = (dataLength) => {
  let size = 22;
  if (dataLength >= 20) {
    size = 16;
  }
  if (dataLength >= 40) {
    size = 10;
  }
  if (dataLength >= 60) {
    size = 2;
  }
  if (window.innerWidth <= 1024) {
    size /= 2;
  }
  return size;
};

export const createCsvData = ({
  columns,
  data,
  hasPermanentTarif,
  hasMixedTarif,
  hasNetworkConsumptionTarif,
  hasSelfConsumptionOfManagerTarif,
  hasTotalSelfConsumptionOfRCPPartnersTarif,
  hasSolarPanelTarif,
}) => {
  const listOfDataRows: any = [];
  listOfDataRows.push(columns);
  data.forEach((dataItem) =>
    listOfDataRows.push([
      dataItem.timeSeriesReadingDate,
      ...(hasPermanentTarif ? [dataItem.permanentTarif] : []),
      ...(hasMixedTarif ? [dataItem.fullPrice] : []),
      ...(hasMixedTarif ? [dataItem.discountPrice] : []),
      ...(hasNetworkConsumptionTarif ? [dataItem.networkConsumptionTarif] : []),
      ...(hasSelfConsumptionOfManagerTarif ? [dataItem.selfConsumptionOfManagerTarif] : []),
      ...(hasTotalSelfConsumptionOfRCPPartnersTarif ? [dataItem.totalSelfConsumptionOfRCPPartnersTarif] : []),
      ...(hasSolarPanelTarif ? [dataItem.solarPanelTarif] : []),
      dataItem.unitOfMeasurement,
    ])
  );
  return listOfDataRows;
};
