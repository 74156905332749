/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import axios from "./http";

const apiSuffix = "/auth";

export const signUpApi = (data = {}) =>
  axios({
    method: "POST",
    url: `${apiSuffix}/register`,
    data,
  });

export const signInApi = (data = {}) =>
  axios({
    method: "POST",
    url: `${apiSuffix}/login`,
    data,
  });

export const forgottenPasswordApi = (data = {}) =>
  axios({
    method: "POST",
    url: `${apiSuffix}/sendemailforpasswordreset`,
    data,
  });

export const resetPasswordApi = (data = {}) =>
  axios({
    method: "POST",
    url: `${apiSuffix}/passwordreset`,
    data,
  });

export const confirmAccountApi = (params) =>
  axios({
    method: "GET",
    url: `emailconfirm/confirmemail${params}`,
  });

  export const validateAccountApi = (email, token) =>
    axios({
      method: "GET",
      url: `emailconfirm/confirmemail/${email}/${token}`,
    });
