/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// react router has limitation that when route changes, it keeps the current scroll of the page
// to avoid that, this component is used and put inside App.tsx component
// this component is catching either header component (for public pages)
// or content of private page layout component (for private pages) and scrolling into them

const ScrollOnTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    document.getElementById("header")?.scrollIntoView();
  }, [pathname]);

  return null;
};

export default ScrollOnTop;
