/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

export const SORTING = {
  DATE_ASC: "+date",
  DATE_DESC: "-date",
  AMOUNT_ASC: "+amount",
  AMOUNT_DESC: "-amount",
};
