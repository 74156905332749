/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Title,
  Input,
  Text,
  Separator,
} from "../../../components/ui";
import { useAppSelector } from "../../../redux/hooks";
import { myProfileSelector, userSelector } from "../auth.selectors";
import AddressesForm from "./forms/AddressesForm";
import PasswordsForm from "./forms/PasswordsForm";
import HomePhoneForm from "./forms/HomePhoneForm";
import MobilePhoneForm from "./forms/MobilePhoneForm";
import styles from "./MyProfileForms.module.scss";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import EmailForm from "./forms/EmailForm";

const cx = classNames.bind(styles);

const MyProfileForms = () => {
  const user = useAppSelector(userSelector);
  const [editableSections, setEditableSections] = useState({
    address: false,
    email: false,
    passwords: false,
    telephoneFixe: false,
    telephoneMobile: false,
  });

  return (
    <Row
      ignorePageContentPadding
      align="start"
      className={styles.myProfileForms}
      direction="column"
    >
      <Col hasPageContentOffset>
        <Title align="left" level={2}>
          Mes coordonnées
        </Title>
      </Col>
      <Col hasPageContentOffset span={12}>
        {/* Prenom and Nom */}
        <Row justify="space-between">
          <Col
            className={cx(styles.formRowSpaceBottom, styles.name)}
            md={3}
            span={12}
          >
            <Input
              disabled
              defaultValue={user.name}
              label="Prénom"
              onFocus={trackClickHandler}
            />
          </Col>
          <Col
            className={cx(styles.formRowSpaceBottom, styles.name)}
            md={3}
            span={12}
          >
            <Input
              disabled
              defaultValue={user.lastName}
              label="Nom / Raison sociale"
              onFocus={trackClickHandler}
            />
          </Col>
        </Row>
        {/* Adresse de contact + Informations de connexion */}
        <Row align="start" justify="space-between">
          {/* Adresse de contact */}
          <Col md={5} span={12}>
            <AddressesForm
              isEditing={editableSections.address}
              onCancel={() => {
                trackClickHandler();
                setEditableSections({ ...editableSections, address: false });
              }}
              onEdit={() => {
                trackClickHandler();
                setEditableSections({ ...editableSections, address: true });
              }}
            />
          </Col>
          {/* Informations de connexion */}
          <Col md={5} span={12}>
            <EmailForm
              isEditing={editableSections.email}
              onCancel={() => {
                trackClickHandler();
                setEditableSections({ ...editableSections, email: false });
              }}
              onEdit={() => {
                trackClickHandler();
                setEditableSections({ ...editableSections, email: true });
              }}
            />
            <PasswordsForm
              isEditing={editableSections.passwords}
              onCancel={() => {
                trackClickHandler();
                setEditableSections({ ...editableSections, passwords: false });
              }}
              onEdit={() => {
                trackClickHandler();
                setEditableSections({ ...editableSections, passwords: true });
              }}
            />
          </Col>
        </Row>
        {/* Téléphones */}
        <Separator />
        <Title className={styles.telephonesTitle} level={3}>
          Téléphone &nbsp;<span className={styles.redDot}>*</span>
        </Title>
        <Text className={styles.formRowSpaceBottom}>
          Merci de compléter au moins l’un des deux numéros de téléphone.
        </Text>
        <Row align="start" justify="space-between">
          <HomePhoneForm
            isEditing={editableSections.telephoneFixe}
            onCancel={() => {
              trackClickHandler();
              setEditableSections({
                ...editableSections,
                telephoneFixe: false,
              });
            }}
            onEdit={() => {
              trackClickHandler();
              setEditableSections({ ...editableSections, telephoneFixe: true });
            }}
          />
          <MobilePhoneForm
            isEditing={editableSections.telephoneMobile}
            onCancel={() => {
              trackClickHandler();
              setEditableSections({
                ...editableSections,
                telephoneMobile: false,
              });
            }}
            onEdit={() => {
              trackClickHandler();
              setEditableSections({
                ...editableSections,
                telephoneMobile: true,
              });
            }}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default MyProfileForms;
