/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable spaced-comment */
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
// import { store } from '../../../redux/store';
import { DownloadIcon, SearchIcon } from "../../../assets/icons";
import {
  addDateBy,
  dateOnly,
  endOfDay,
  formatDate,
  subtractDateBy,
} from "../../../common/helpers/dates";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import {
  Button,
  Checkbox,
  Col,
  ControlledInput,
  Input,
  Link,
  Loader,
  Row,
  Separator,
  Table,
  Text,
  Title,
} from "../../../components/ui";
import ConsumptionGraph from "../../../components/ui/consumptionGraph/ConsumptionGraph";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { toastSuccess } from "../../../services";
import styles from "./Consumption.module.scss";
import {
  downloadCSV,
  fetchConsumptionAndAddresses,
  fetchConsumptionHistory,
  fetchConsumptionHistoryForSecondChart,
} from "./service/consumption.actions";
import { limitAddresses, searchAddresses } from "./service/consumption.helpers";
import {
  consumptionAddressesSelector,
  consumptionHistoryForSecondChartSelector,
  consumptionHistorySelector,
  consumptionSelector,
  isComplexConsumptionSelector,
  isFetchingConsumptionAndAddressesSelector,
  isFetchingConsumptionCsvSelector,
  isFetchingConsumptionHistoryForSecondChartSelector,
  isFetchingConsumptionHistorySelector,
} from "./service/consumption.selectors";
import { PAGES } from "../../../common/constants/common";

type ChartDataData = {
    fullPrice: number | null,
    discountPrice: number | null,
    permanentTarif: number | null,
    solarPanelTarif: number | null,
    solarPanelTarifFullPrice: number | null,
    solarPanelTarifDiscountPrice: number | null,
    selfConsumptionOfManagerTarif: number | null,
    selfConsumptionOfManagerTarifFullPrice: number | null,
    selfConsumptionOfManagerTarifDiscountPrice: number | null,
    totalSelfConsumptionOfRCPPartnersTarif: number | null,
    totalSelfConsumptionOfRCPPartnersTarifFullPrice: number | null,
    totalSelfConsumptionOfRCPPartnersTarifDiscountPrice: number | null,
    name: string,
    elementOfInvoicing: any,
    pointTheMeasure: any,
    unitOfMeasurement: any
}

interface ChartData {
  barSize: number;
  data: ChartDataData[];
  hasPermanentTarif: boolean;
  hasSolarPanelTarif: boolean;
  totalConsumption: number;
}

const Consumption = () => {
  const dispatch = useAppDispatch();
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const [comparePeriods, setComparePeriods] = useState(false);
  const [checkedAddresses, setCheckedAddresses] = useState([
    { addressId: "ALL", checked: false },
  ]);
  const [addressSearchValue, setAddressSearchValue] = useState("");
  const [dateRangesError, setDateRangesError] = useState("");

  const [firstBarChartDates, setFirstBarChartDates] = useState({
    from: subtractDateBy(dateOnly(), "year", 1),
    to: dateOnly(),
  });
  const [secondBarChartDateFrom, setSecondBarChartDateFrom] = useState(
    new Date(firstBarChartDates.from)
  );
  const [secondBarChartDateTo, setSecondBarChartDateTo] = useState(
    new Date(firstBarChartDates.to)
  );

  const chartTimePeriod = Math.round(
    (firstBarChartDates.to.getTime() - firstBarChartDates.from.getTime()) /
      (1000 * 3600 * 24)
  );
  const [chartPeriod, setChartPeriod] = useState("");
  const [syncedDaysValue, setSyncedDaysValue] = useState("");

  const isComplex = useAppSelector(isComplexConsumptionSelector);
  const allAddresses: any = useAppSelector(consumptionAddressesSelector);
  const consumption = useAppSelector(consumptionSelector);
  const isFetchingConsumptionAndAddresses = useAppSelector(
    isFetchingConsumptionAndAddressesSelector
  );
  const isFetchingConsumptionHistory = useAppSelector(
    isFetchingConsumptionHistorySelector
  );
  const isFetchingConsumptionHistoryForSecondChart = useAppSelector(
    isFetchingConsumptionHistoryForSecondChartSelector
  );
  const isFetchingCsv = useAppSelector(isFetchingConsumptionCsvSelector);

  const firstBarChartData: any = useAppSelector(consumptionHistorySelector);
  const secondBarChartData: any = useAppSelector(
    consumptionHistoryForSecondChartSelector
  );
  // const [secondBarChartData, setSecondBarChartData]: any = useState(useAppSelector(consumptionHistoryForSecondChartSelector));

  const addressesIds = checkedAddresses
    .filter((address) => address.checked && address.addressId !== "ALL")
    .map((address) => address.addressId);

  const getDataForAddressesTable = (addressesData) =>
    addressesData.map((address) => ({
      key: address.addressId,
      address: address.address,
      installationNumber: (
        <div>
          {address.pointTheMeasure?.slice(0, 20)}
          <br />
          {address.pointTheMeasure?.slice(20)}
        </div>
      ),
      counters: address.listOfCountersAndInstallations?.map(
        (counterAndInstallation) => (
          <div key={counterAndInstallation.counterId}>
            {counterAndInstallation.counterId}
          </div>
        )
      ),
    }));

  // const totalResultsAddresses = () => {
  //   let total = 0;
  //   allAddresses.forEach(address => {
  //     if (address.listOfCountersAndInstallations) {
  //       total += address.listOfCountersAndInstallations.length;
  //     }
  //   });
  //   return total;
  // };
  const totalResultsAddresses = () =>
    allAddresses.reduce(
      (total, address) =>
        total + (address.listOfCountersAndInstallations?.length || 0),
      0
    ) - limitAddresses(allAddresses).length;

  const formatAddressesData = (addressesData) =>
    addressesData.map((address) => ({
      ...address,
      checked: checkedAddresses.find(
        (addressItem) => addressItem.addressId === address.key
      )?.checked,
    }));

  const getMeasuringPointsForCheckedAddresses = () =>
    isComplex
      ? allAddresses
          .filter((address) => addressesIds.includes(address.addressId))
          .filter((address) => address.pointTheMeasure)
          .map((address) => ({
            contractType: address.contractType,
            code: address.pointTheMeasure,
            installationIds: address.listOfCountersAndInstallations
              ? address.listOfCountersAndInstallations.map(
                  (installation) => installation.installationId
                )
              : [],
          }))
      : allAddresses
          .filter((address) => address.pointTheMeasure)
          .map((address) => ({
            contractType: address.contractType,
            code: address.pointTheMeasure,
            installationIds: address.listOfCountersAndInstallations
              ? address.listOfCountersAndInstallations.map(
                  (installation) => installation.installationId
                )
              : [],
          }));

  const onDownloadCSV = () => {
    toastSuccess({
      message:
        "La création de votre fichier .csv peut prendre plusieurs dizaines de secondes.",
    });
    dispatch(
      downloadCSV({
        csvDateRanges: [
          {
            startDate: firstBarChartDates.from,
            endDate: endOfDay(firstBarChartDates.to),
          },
          {
            startDate: secondBarChartDateFrom,
            endDate: endOfDay(secondBarChartDateTo),
          },
        ].slice(0, comparePeriods ? 2 : 1),
        consumptionGraphCsvs: isComplex
          ? allAddresses
              .filter((address) => addressesIds.includes(address.addressId))
              .map((addressItem) => ({
                contractType: addressItem.contractType,
                addressId: addressItem.addressId,
                pointTheMeasure: addressItem.pointTheMeasure,
                installationIds: addressItem.listOfCountersAndInstallations
                  ? addressItem.listOfCountersAndInstallations.map(
                      (installation) => installation.installationId
                    )
                  : [],
              }))
          : [
              {
                contractType: allAddresses[0]?.contractType,
                addressId: allAddresses[0]?.addressId,
                pointTheMeasure: allAddresses[0]?.pointTheMeasure,
                installationIds: allAddresses[0]?.listOfCountersAndInstallations
                  ? allAddresses[0]?.listOfCountersAndInstallations.map(
                      (installation) => installation.installationId
                    )
                  : [],
              },
            ],
      })
    );
  };

  // calculate and set date for second graph
  const setSyncedDaysValueHandler = () => {
    const date = dateOnly();

    date.setDate(date.getDate() - chartTimePeriod);
    setSyncedDaysValue(date.toLocaleDateString("en-CA"));
  };

  // fetching consumption pie chart and addresses
  useEffect(() => {
    dispatch(fetchConsumptionAndAddresses());
  }, []);

  // when addresses are fetched, set new state for addresses checkboxes
  useEffect(() => {
    setCheckedAddresses([
      { addressId: "ALL", checked: false },
      ...allAddresses.map((address) => ({
        addressId: address.addressId,
        checked: false,
      })),
    ]);
  }, [allAddresses]);

  // fetching first graph
  useEffect(() => {
    if (addressesIds.length > 0 || !isComplex) {
      if (firstBarChartDates.from <= firstBarChartDates.to) {
        dispatch(
          fetchConsumptionHistory({
            startDate: firstBarChartDates.from,
            endDate: firstBarChartDates.to,
            pointTheMeasures: getMeasuringPointsForCheckedAddresses(),
          })
        );
      }
    }
    if (firstBarChartDates.from > firstBarChartDates.to) {
      setDateRangesError(
        "La date de début doit être antérieure à la date de fin"
      );
    } else {
      setDateRangesError("");
    }

    if (chartTimePeriod === 0 || chartTimePeriod === 1) {
      setChartPeriod("vue journalière");
    }
    if (chartTimePeriod > 1) {
      setChartPeriod("vue hebdomadaire");
    }
    if (chartTimePeriod >= 8) {
      setChartPeriod("vue mensuelle");
    }
    if (chartTimePeriod >= 120) {
      setChartPeriod("vue annuelle");
    }
  }, [firstBarChartDates, checkedAddresses]);
  // fetching second graph
  useEffect(() => {
    if (comparePeriods && (addressesIds.length > 0 || isComplex === false)) {
      dispatch(
        fetchConsumptionHistoryForSecondChart({
          startDate: secondBarChartDateFrom,
          endDate: secondBarChartDateTo,
          pointTheMeasures: getMeasuringPointsForCheckedAddresses(),
        })
      );
    }
  }, [comparePeriods, secondBarChartDateTo, checkedAddresses]);

  useEffect(() => {
    setSecondBarChartDateFrom(addDateBy(firstBarChartDates.from, "day", 0));
    const newDate = addDateBy(
      new Date(secondBarChartDateFrom),
      "day",
      chartTimePeriod
    );

    setSecondBarChartDateTo(newDate);
  }, [firstBarChartDates.from]);

  useEffect(() => {
    const newDate = addDateBy(
      new Date(secondBarChartDateFrom),
      "day",
      chartTimePeriod
    );
    setSecondBarChartDateTo(newDate);
  }, [secondBarChartDateFrom, firstBarChartDates.to]);

  function barChartDataConversion(chartData: ChartData) {
    const convertedData = chartData?.data?.map((itemObj) => {
      const newObj = {};

      Object.entries(itemObj).forEach(([key, value]) => {
        newObj[key] = value === 0 ? null : value;
      });

      return newObj;
    });

    const newChartData = {
      ...chartData,
      data: convertedData,
    };

    return newChartData;
  }

  function checkEnergyHasValue(chartData: ChartDataData[], key: string) {
    return chartData?.some((item) => item[key] !== null);
  }

  const renderBarMenuAndBarChart = ({ barChartNumber = 1, data }) => (
    <>
      <Col span={12}>
        <Row
          wrapForMobile
          align="center"
          className={styles.consumptionGraphOptions}
          justify="space-between"
        >
          <Col lg={9} span={12}>
            <Row align="start" justify="start">
              <Col hasPageContentRightOffset lg={3} md={6} span={10} xl={2}>
                <ControlledInput
                  className={styles.inputDate}
                  error={dateRangesError}
                  label="Période du :"
                  max="2040.31.12"
                  min="2023.01.01"
                  type="date"
                  value={
                    barChartNumber === 1
                      ? formatDate(new Date(firstBarChartDates.from), "input")
                      : formatDate(new Date(secondBarChartDateFrom), "input")
                  }
                  onChange={(event) => {
                    trackClickHandler();
                    if (barChartNumber === 1) {
                      setFirstBarChartDates({
                        ...firstBarChartDates,
                        from: new Date(event.target.value),
                      });
                    } else {
                      setSecondBarChartDateTo(
                        addDateBy(secondBarChartDateTo, "day", chartTimePeriod)
                      );
                      setSecondBarChartDateFrom(new Date(event.target.value));
                    }
                  }}
                />
              </Col>
              <Col hasPageContentRightOffset lg={3} md={6} span={10} xl={2}>
                <ControlledInput
                  className={styles.inputDate}
                  disabled={barChartNumber === 2}
                  label="au :"
                  type="date"
                  value={
                    barChartNumber === 1
                      ? formatDate(new Date(firstBarChartDates.to), "input")
                      : formatDate(new Date(secondBarChartDateTo), "input")
                  }
                  onChange={(event) => {
                    trackClickHandler();

                    setFirstBarChartDates({
                      ...firstBarChartDates,
                      to: new Date(event.target.value),
                    });
                  }}
                />
              </Col>
              {barChartNumber === 1 && (
                <Col
                  hasPageContentRightOffset
                  className={styles.comparePeriodsCheckboxWrapper}
                >
                  <Checkbox
                    className={styles.comparePeriodsCheckbox}
                    id="comparePeriods"
                    onChange={() => {
                      setSyncedDaysValueHandler();
                      setComparePeriods(!comparePeriods);
                    }}
                  >
                    Comparer deux périodes
                  </Checkbox>
                </Col>
              )}
            </Row>
          </Col>

          {/* Download button */}
          {barChartNumber === 1 && data?.data?.length > 0 && (
            <Col className={styles.downloadButtonWrapper} lg={3} span={12}>
              <Button
                className={styles.downloadButton}
                suffix={<DownloadIcon className={styles.downloadIcon} />}
                onClick={onDownloadCSV}
              >
                {isFetchingCsv ? "Téléchargement..." : "Télécharger"}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
      <Separator size="large" />
      {(isFetchingConsumptionHistory && barChartNumber === 1) ||
      (isFetchingConsumptionHistoryForSecondChart && barChartNumber === 2) ? (
        <Loader type="content" />
      ) : (
        <Col hasPageContentOffset className={styles.barWrapper} span={12}>
          {data?.totalConsumption > 0 || data?.hasSolarPanelTarif ? (
            <>
              <p className={styles.timePeriod}>{chartPeriod}</p>
              <ResponsiveContainer height={350} width="100%">
                <BarChart data={data?.data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" style={{ display: "none" }} />
                  <YAxis />
                  <Tooltip
                    formatter={(value: number) =>
                      value &&
                      `${String(value.toLocaleString("en-US")).replace(
                        /,/g,
                        "'"
                      )} kWh`
                    }
                  />
                  <Legend />
                  <Bar
                    dataKey="permanentTarif"
                    fill="#8a1e12"
                    legendType={checkEnergyHasValue(data?.data, 'permanentTarif') ? "square" : "none"}
                    name="Consommation - Permanent"
                    stackId="consumption"
                  />
                  <Bar
                    dataKey="discountPrice"
                    fill="#d58080"
                    legendType={checkEnergyHasValue(data?.data, 'discountPrice') ? "square" : "none"}
                    name="Consommation - Heures creuses"
                    stackId="consumption"
                  />
                  <Bar
                    dataKey="fullPrice"
                    fill="#e6321e"
                    legendType={checkEnergyHasValue(data?.data, 'fullPrice') ? "square" : "none"}
                    name="Consommation - Heures pleines"
                    stackId="consumption"
                  />
                  {/* <Bar
                    dataKey="networkConsumptionTarif"
                    fill="#CF1804"
                    legendType={checkEnergyHasValue(data?.data, 'networkConsumptionTarif') ? "square" : "none"}
                    name="Consommation du réseau"
                    stackId="consumption"
                  /> */}
                  <Bar
                    dataKey="selfConsumptionOfManagerTarifDiscountPrice"
                    fill="#C6D084"
                    legendType={checkEnergyHasValue(data?.data, 'selfConsumptionOfManagerTarifDiscountPrice') ? "square" : "none"}
                    name="Autoconsommation production solaire - Heures Creuses"
                    stackId="consumption"
                  />
                  <Bar
                    dataKey="selfConsumptionOfManagerTarifFullPrice"
                    fill="#98A444"
                    legendType={checkEnergyHasValue(data?.data, 'selfConsumptionOfManagerTarifFullPrice') ? "square" : "none"}
                    name="Autoconsommation production solaire - Heures Pleines"
                    stackId="consumption"
                  />
                  <Bar
                    dataKey="totalSelfConsumptionOfRCPPartnersTarifDiscountPrice"
                    fill="#80CBD5"
                    legendType={checkEnergyHasValue(data?.data, 'totalSelfConsumptionOfRCPPartnersTarifDiscountPrice') ? "square" : "none"}
                    name="Totalité autoconsommation des participants du RCP - Heures Creuses"
                    stackId="consumption"
                  />
                  <Bar
                    dataKey="totalSelfConsumptionOfRCPPartnersTarifFullPrice"
                    fill="#2E747D"
                    legendType={checkEnergyHasValue(data?.data, 'totalSelfConsumptionOfRCPPartnersTarifFullPrice') ? "square" : "none"}
                    name="Totalité autoconsommation des participants du RCP - Heures Pleines"
                    stackId="consumption"
                  />
                  <Bar
                    dataKey="solarPanelTarifDiscountPrice"
                    fill="#89D580"
                    legendType={checkEnergyHasValue(data?.data, 'solarPanelTarifDiscountPrice') ? "square" : "none"}
                    name="Injection - Heures Creuses"
                    stackId="consumption"
                  />
                  <Bar
                    dataKey="solarPanelTarifFullPrice"
                    fill="#337D2C"
                    legendType={checkEnergyHasValue(data?.data, 'solarPanelTarifFullPrice') ? "square" : "none"}
                    name="Injection - Heures Pleines"
                    stackId="consumption"
                  />
                </BarChart>
              </ResponsiveContainer>
              <p className={styles.kwhLabel}>kWh</p>
              <Title align="center" level={3}>
                Énergie totale :{" "}
                {Number(data?.totalConsumption.toFixed(2))
                  .toLocaleString("en-US")
                  .replace(/,/g, "'")}{" "}
                kWh sur la période sélectionnée
              </Title>
              <Separator size="medium" />
              <Text align="center">
                En fonction de votre type de compteur, votre historique de
                consommation peut présenter des pics de consommations. Ces pics
                sont liés aux relevés périodiques : mensuels ou trimestriels.
              </Text>
              <br />
              <br />
              <Text align="center">
                Vous avez un compteur intelligent, mais ne visualisez pas votre historique de consommation? Merci de{" "}
                <Link to={PAGES.CONTACT_AND_FORMS}>
                  prendre contact avec nos services
                </Link>{" "}pour activer l&apos;accès à vos données.
              </Text>
            </>
          ) : (
            <Title align="center" level={3}>
              Aucune donnée disponible
            </Title>
          )}
        </Col>
      )}
    </>
  );

  if (isFetchingConsumptionAndAddresses) {
    return <Loader />;
  }

  return (
    <Row align="start" className={styles.consumption} direction="column">
      <Col>
        <Title>Ma consommation</Title>
      </Col>
      <Col span={12}>
        <Row wrapForMobile align="stretch" justify="space-between" wrap={false}>
          <ConsumptionGraph data={consumption} />
          <Col className={styles.consumptionProfile} span={12}>
            <Title isBoxTitle className="red" level={3}>
              Informations sur les profils de consommation
            </Title>
            <Text isLight>Solo :</Text>
            <Text className={styles.consumptionProfileText}>
              Ce profil est destiné aux clients consommant de l’énergie
              principalement durant la journée. Il s’applique à la majorité des
              ménages et petites entreprises.
            </Text>
            <Text isLight>Modulo :</Text>
            <Text className={styles.consumptionProfileText}>
              Ce profil convient aux ménages ou professionnels qui ont la
              possibilité de moduler leur consommation d’électricité en fonction
              des heures pleines et des heures creuses. Il est également
              conseillé aux ménages avec application thermique (pompe à chaleur,
              chauffage électrique, chauffe-eau) ou possédant un appareil à
              forte consommation d’électricité qui peut être enclenché durant
              les heures creuses.
            </Text>
            <Text>
              Si votre consommation annuelle est supérieure à 100&apos;000 kWh,
              vous avez accès à d’autres profils de consommation. Plus de
              détails sur la page{" "}
              <Link href="https://www.sie.ch/electricite/tarifs-et-liste-de-prix-267">
                Tarifs et liste de prix
              </Link>{" "}
              de notre site Internet.
            </Text>
          </Col>
        </Row>
      </Col>
      <Separator size="large" />
      <Col span={12}>
        <Row ignorePageContentPadding align="start" direction="column">
          <Col hasPageContentOffset>
            <Title level={2}>Historique de consommation</Title>
            {isComplex && (
            <Title className={styles.selectConsumationTitle} level={3}>
              Sélectionner vos lieux de consommation
            </Title>
              )}
          </Col>
          {/* ADDRESSES TABLE */}
          {isComplex && (
          <Col hasPageContentOffset span={12}>
            <Input
              className={styles.searchInput}
              disabled={addressesIds.length > 0}
              placeholder="Recherche par adresse, n° de compteur, n° d’installation ..."
              prefix={<SearchIcon />}
              onChange={(e) => setAddressSearchValue(e.target.value)}
                />
            <Separator />
            <Table
              checkableFirstColumn
              checkableRows
              columns={[
                    {
                      dataIndex: "address",
                      title: "Adresses",
                      checked: checkedAddresses[0].checked,
                    },
                    {
                      dataIndex: "installationNumber",
                      title: "Numéro de point de mesure",
                    },
                    { dataIndex: "counters", title: "Compteur" },
                  ]}
              data={formatAddressesData(
                    showAllAddresses
                      ? getDataForAddressesTable(
                          searchAddresses(allAddresses, addressSearchValue)
                        )
                      : getDataForAddressesTable(
                          limitAddresses(
                            searchAddresses(allAddresses, addressSearchValue)
                          )
                        )
                  )}
              hasShowAllButton={
                    !showAllAddresses &&
                    allAddresses.length !== limitAddresses(allAddresses).length
                  }
              id="consumptionTable"
              totalResults={totalResultsAddresses()}
              onColumnChecked={(e) => {
                    trackClickHandler();
                    setCheckedAddresses(
                      checkedAddresses.map((address) => ({
                        ...address,
                        checked: e.target.checked,
                      }))
                    );
                  }}
              onRowChecked={(e) => {
                    trackClickHandler();
                    setCheckedAddresses([
                      { addressId: "ALL", checked: false },
                      ...checkedAddresses
                        .map((address) => ({
                          ...address,
                          checked:
                            +e.target.name === +address.addressId
                              ? e.target.checked
                              : address.checked,
                        }))
                        .slice(1),
                    ]);
                  }}
              onShowAllResults={() => setShowAllAddresses(true)}
                />
            <Separator />
            <Separator />
          </Col>
            )}
          {(addressesIds.length > 0 || !isComplex) &&
              renderBarMenuAndBarChart({
                barChartNumber: 1,
                data: barChartDataConversion(firstBarChartData),
              })}
          <Separator size="large" />
          {comparePeriods &&
              (addressesIds.length > 0 || !isComplex) &&
              renderBarMenuAndBarChart({
                barChartNumber: 2,
                data: barChartDataConversion(secondBarChartData),
              })}
        </Row>
      </Col>
    </Row>
  );
};

export default Consumption;
