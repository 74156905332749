/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PDFIcon, QuestionCircleIcon } from '../../../assets/icons';
import { PAGES } from '../../../common/constants/common';
import { Button, Col, Dropdown, Input, Link, Loader, Row, Separator, Table, Text, Title } from '../../../components/ui';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { toastError } from '../../../services';
import styles from './MeterReadings.module.scss';
import MeterReadingsTables from './MeterReadingsTables';
import { clearCounters, fetchCounters, fetchMeterReadingsData, postCounter } from './service/meterReadings.actions';
import trackHistory from '../../../common/helpers/trackHistory';
import trackClickHandler from '../../../common/helpers/trackClickHandler';
import { countersSelector, isFetchingCountersSelector, isFetchingMeterReadingsSelector, isSubmittingMeterReadingsSelector, meterReadingsSelector } from './service/meterReadings.selectors';

const MeterReadings = () => {
  const location = window.location.href;
  const dispatch = useAppDispatch();
  const isFetchingPageContent = useAppSelector(isFetchingMeterReadingsSelector);
  const isFetchingCounters = useAppSelector(isFetchingCountersSelector);
  const isSubmitting = useAppSelector(isSubmittingMeterReadingsSelector);
  const meterReadings = useAppSelector(meterReadingsSelector);
  const counters = useAppSelector(countersSelector);
  const [counterReading, setCounterReading] = useState<{ value: string, couldronId: string }[]>([]);
  const [meterId, setMeterId] = useState('');
  const columns = [
    { dataIndex: 'counterNumber', title: 'Compteur numéro' },
    { dataIndex: 'meterReadingDate', title: 'Date du relevé' },
    { dataIndex: 'tourNumber', title: 'Numéro de tournée' },
    { dataIndex: 'dial', title: 'Cadran**' },
    { dataIndex: 'readingInKwh', title: 'Relevé en kWh*' },
    { dataIndex: 'actions', title: '' }
  ];

  const defaultCounters = counters.map((counter, i) => (
    { [`input${i}`]: '' }
  ));

  const {
    register, handleSubmit, formState: { errors }
  } = useForm({
    defaultValues: {
      dateTime: '',
      touree: '',
      releve: '',
      ...defaultCounters
    },
    shouldUnregister: true
  });

  const onMeterReadingSelectChange = (event) => {
    setMeterId(event.target.value);
    const mid = event.target.value;
    const objId = meterReadings.find(meterReading => meterReading.meterId === mid)?.objId;
    setCounterReading([]);
    dispatch(fetchCounters({ mid, objId }));
    trackClickHandler();
  };

  const onCounterEdit = (data) => {
    if (counterReading.find(reading => reading.value === '' || reading.value === undefined)) {
      toastError({ message: 'Veuillez saisir la lecture' });
      return;
    }
    const response = {
      ...data,
      cadranReading: counterReading,
      meterId
    };

    dispatch(postCounter(response));
  };

  const meterReadingsDataForTable = [
    {
      key: 'meterReadingsFirstItem',
      counterNumber: <Dropdown
        id="meterReadingsDropdown"
        name="meterReadingsDropdown"
        options={meterReadings.map(meterReading => meterReading.meterId)}
        placeholder="Sélectionner le numéro du compteur"
        onChange={onMeterReadingSelectChange}
      />,
      meterReadingDate: counters[0] ? <Input {...register('dateTime', { required: true })} error={(errors.dateTime?.type === 'required' && 'Date du relevé est requis')} type="date" /> : '',
      tourNumber: counters[0] ? <Input type="number" {...register('touree')} className={styles.tourNumberInput} /> : '',
      dial: counters[0]?.cadranId || null,
      readingInKwh: counters[0] ?
        <Input
          required
          {...register('input0', { required: true, maxLength: 6 })}
          error={(errors.input0?.type === 'required' && 'Cette case est nécessaire') || (errors.input0?.type === 'maxLength' && '6 chiffres possibles')}
          type="number"
          onChange={(e) => {
            trackClickHandler();
            if (!e.target.value.trim()) {
              return;
            }
            const foundIndex = counterReading.map(cr => cr.couldronId).indexOf(counters[0]?.cadranId);
            if (foundIndex < 0) {
              setCounterReading(arr => [...arr, { value: e.target.value, couldronId: counters[0]?.cadranId }]);
            } else {
              setCounterReading(arr => [...arr.slice(0, foundIndex),
                { value: e.target.value, couldronId: counters[0]?.cadranId },
                ...arr.slice(foundIndex + 1)]);
            }
          }} /> : '',
      actions: counters[0] ? (
        <Button
          loading={isSubmitting}
          type="submit"
          onClick={trackClickHandler}
        >
          Envoyer
        </Button>) : null,
    },
    ...counters.slice(1).map((counter, i) => ({
      key: counter.cadranId,
      counterNumber: '',
      meterReadingDate: null,
      tourNumber: null,
      dial: counter.cadranId,
      readingInKwh: <Input
        {...register(`input${i + 1}`, { maxLength: 6 })}
        error={(errors[`input${i + 1}`]?.type === 'maxLength' && '6 chiffres possibles')}
        type="number"
        onChange={(e) => {
          trackClickHandler();
          if (!e.target.value.trim()) {
            return;
          }
          const foundIndex = counterReading.map(cr => cr.couldronId).indexOf(counter.cadranId);
          if (foundIndex < 0) {
            setCounterReading(arr => [...arr, { value: e.target.value, couldronId: counter.cadranId }]);
          } else {
            setCounterReading(arr => [...arr.slice(0, foundIndex),
              { value: e.target.value, couldronId: counter.cadranId },
              ...arr.slice(foundIndex + 1)]);
          }
        }} />,
      actions: null,
    }))
  ];

  useEffect(() => {
    dispatch(fetchMeterReadingsData());
    return () => {
      dispatch(clearCounters());
    };
  }, []);

  useEffect(() => () => {
    trackHistory(sessionStorage.getItem('clicks') ?? 0, location);
    sessionStorage.removeItem('clicks');
  }, []);

  if (isFetchingPageContent) {
    return <Loader />;
  }

  return (
    <>
      <Title>
        Mes relevés de compteurs
      </Title>
      <Row
        wrapForMobile align="stretch" justify="space-between"
        wrap={false}
      >
        <Col
          isContentBox className={styles.helpBox} md={5}
          span={12}
        >
          <Title isBoxTitle level={3}>Comment faire ?</Title>
          <Text>Besoin d’aide pour relever vos compteurs ?</Text>
          <Separator />
          <QuestionCircleIcon />
          <Separator />
          {/* <Link
            isLight className={styles.link} href="TODO"
            onClick={trackClickHandler}>Tutoriel pdf
          </Link> */}
          <Link
            isLight className={styles.link} to={PAGES.HELP_AND_FAQS}
            onClick={trackClickHandler}>Notre rubrique d’aide
          </Link>
        </Col>
        <Col
          className={styles.informationBox} md={7}
          span={12}
        >
          <Title level={3}>Informations</Title>
          <Text>
            Vous pouvez effectuer vos relevés de compteurs via votre Espace Client. Si vous préférez, vous pouvez aussi imprimer le formulaire dédié et nous le renvoyer par email à comptage@sie.ch ou par courrier à l’adresse suivante :
          </Text>
          <Separator size="small" />
          <Text>SIE SA</Text>
          <Text>Case postale</Text>
          <Text>1023 Crissier</Text>
          <Separator />
          <Row wrap={false}>
            <Link className={styles.informationBoxLink} href="https://www.sie.ch/media/document/0/sie-recto-verso-a5-releve-compteur-preview.pdf" onClick={trackClickHandler}>
              Télécharger le formulaire de relevé de compteurs en version papier
            </Link>
            <PDFIcon className={styles.pdfIcon} />
          </Row>
        </Col>
      </Row>
      <Separator size="large" />
      <Row
        ignorePageContentPadding align="start" direction="column"
        justify="start"
      >
        <Col hasPageContentOffset span={12}>
          <Title level={2}>Relever mon compteur</Title>
          <Text size="small">*Champs obligatoires</Text>
          <Text size="small">**Si votre compteur n’indique pas de cadran, entrez votre index dans le premier champ disponible ci-dessous</Text>
        </Col>
        <Separator />
        <Col hasPageContentOffset className={styles.meterReadingsTable} span={12}>
          <form noValidate onSubmit={handleSubmit(onCounterEdit)}>
            <Table
              columns={columns}
              data={meterReadingsDataForTable}
              id="simpleMeterReadingsTable"
              loading={isFetchingCounters}
            />
          </form>
        </Col>
        <Separator />
        <Separator />
        <MeterReadingsTables />
      </Row>
    </>
  );
};

export default MeterReadings;
