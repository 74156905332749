/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

export const FETCH_MULTIMEDIA_OFFER_START =
  "MY_MULTIMEDIA_OFFER::FETCH_MULTIMEDIA_OFFER_START";
export const FETCH_MULTIMEDIA_OFFER_SUCCESS =
  "MY_MULTIMEDIA_OFFER::FETCH_MULTIMEDIA_OFFER_SUCCESS";
export const FETCH_MULTIMEDIA_OFFER_FAILURE =
  "MY_MULTIMEDIA_OFFER::FETCH_MULTIMEDIA_OFFER_FAILURE";
