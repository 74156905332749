/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Title,
  Text,
  Separator,
  Loader,
  Link,
} from "../../../components/ui";
import MyActiveOffersImage from "../../../assets/images/energy.jpg";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  electricityOfferSelector,
  myProfileSelector,
  userSelector,
} from "../auth.selectors";
import MyProfileForms from "./MyProfileForms";
import styles from "./MyProfile.module.scss";
import { PAGES, SCENARIOS } from "../../../common/constants/common";
import { fetchElectricityOffer, fetchMyProfileData } from "../auth.actions";
import MyProfileInvoiceReception from "./MyProfileInvoiceReception";
import trackHistory from "../../../common/helpers/trackHistory";
import trackClickHandler from "../../../common/helpers/trackClickHandler";

const cx = classNames.bind(styles);

const MyProfile = () => {
  const location = window.location.href;
  const dispatch = useAppDispatch();
  const { scenario, id } = useAppSelector(userSelector);
  const user = useAppSelector(userSelector) || {
    name: "Pierre",
    lastName: "Martin",
    id: "000000",
    connectionNumber: "000000",
  };
  const electricityOffer = useAppSelector(electricityOfferSelector);
  const myProfile = useAppSelector(myProfileSelector);
  const [offer, setOffer] = useState<any>();
  const [mediaOffer, setMediaOffer] = useState<string | undefined>();

  // Set energie offer
  // const energyOffer = () => {
  //   if (offer === 'SIE Nature') {
  //     return 'SIE Nature';
  //   }
  //   if (offer === 'Energie Suisse') {
  //     return 'SIE Nature';
  //   }
  //   if (offer === 'SIE Nature +') {
  //     return 'SIE Nature +';
  //   }
  //   if (offer === 'Energie Romande') {
  //     return 'SIE Nature +';
  //   }
  //   if (offer === 'SIE Basic') {
  //     return 'SIE Basic';
  //   }
  //   if (offer === 'SIE Force Hydro EU') {
  //     return 'SIE Force Hydro EU';
  //   }
  //   if (offer === 'SIE Force Hydro +') {
  //     return 'SIE Force Hydro +';
  //   }
  //   if (offer === 'SIE Personnalisable') {
  //     return 'SIE personnalisée';
  //   }
  //   return 'Aucune';
  // };

  // Set multimedia offer
  // const multimediaOffer = () => {
  //   if (user?.scenario === 0) {
  //     return 'Offre partenaire';
  //   }
  //   if (user?.scenario === 1) {
  //     return 'Aucune';
  //   }
  //   if (user?.scenario === 2) {
  //     return 'Basic';
  //   }
  //   if (user?.scenario === 3) {
  //     return 'Basic';
  //   }
  //   if (user?.scenario === 4) {
  //     return 'Offre partenaire';
  //   }
  //   return 'Offre partenaire';
  // };

  useEffect(() => {
    dispatch(fetchMyProfileData());
    dispatch(fetchElectricityOffer());
  }, []);

  useEffect(
    () => () => {
      trackHistory(sessionStorage.getItem("clicks") ?? 0, location);
      sessionStorage.removeItem("clicks");
    },
    []
  );

  useEffect(() => {
    setOffer(electricityOffer?.econtractType);
    setMediaOffer(electricityOffer?.mcontractType);
  }, [electricityOffer]);

  if (!myProfile || !electricityOffer) {
    return <Loader type="page" />;
  }

  return (
    <>
      <Row align="start" className={styles.myProfile} direction="column">
        <Col>
          <Title>Mon profil</Title>
        </Col>
        <Col span={12}>
          <Row wrapForMobile align="start" justify="space-between" wrap={false}>
            <Col isContentBox className={styles.myProfileInfo} md={5} span={12}>
              <Title
                isBoxTitle
                align="center"
                className={styles.customerInfoTitle}
                level={3}
              >
                Informations client
              </Title>
              <Row
                align="start"
                className={styles.clientInformationsRow}
                justify="space-between"
              >
                <Col className={styles.customerInfoBox} span={5}>
                  Prénom
                  <Text isBold className={styles.customerInfoBoxValue}>
                    {user.name}
                  </Text>
                </Col>
                <Col className={styles.customerInfoBox} span={5}>
                  Numéro client
                  <Text isBold className={styles.customerInfoBoxValue}>
                    {user.id}
                  </Text>
                </Col>
              </Row>
              <Row
                align="start"
                className={styles.clientInformationsRow}
                justify="space-between"
              >
                <Col
                  className={cx(
                    styles.customerInfoBox,
                    styles.customerInfoBoxNoBorder
                  )}
                  span={5}
                >
                  Nom / Raison sociale
                  <Text isBold className={styles.customerInfoBoxValue}>
                    {user.lastName}
                  </Text>
                </Col>
                <Col
                  className={cx(
                    styles.customerInfoBox,
                    styles.customerInfoBoxNoBorder
                  )}
                  span={5}
                >
                  Numéro de point de mesure
                  <Text isBold className={styles.customerInfoBoxValue}>
                    {user.connectionNumber}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col
              isContentBox
              className={styles.mySubscription}
              md={7}
              span={12}
            >
              <Title
                isBoxTitle
                align="center"
                className={styles.mySubscriptionTitle}
                level={3}
              >
                Mes offres actives
              </Title>
              <Link
                className="red"
                to={PAGES.MY_ENERGY_OFFER}
                onClick={trackClickHandler}
              >
                Offre énergie
              </Link>
              <Text>{!offer ? "Aucune" : offer}</Text>
              <Separator size="small" />
              <Link
                className="blue"
                to={PAGES.MY_MULTIMEDIA_OFFER}
                onClick={trackClickHandler}
              >
                Offre multimédia
              </Link>
              <Text>
                {mediaOffer ?? "Aucune"}
              </Text>
              <img
                alt="active-offer"
                className={styles.myActiveOffersImage}
                src={MyActiveOffersImage}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Separator size="large" />
      <MyProfileForms />
      <Separator size="large" />
      <MyProfileInvoiceReception />
    </>
  );
};

export default MyProfile;
