/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { subscribeToNewsletterRequest } from "../modules/electricityAndMultimedia/dashboard/service/dashboard.types";
import axios from "./http";

const apiDashboardSuffix = "/info";

export const fetchDashboardComplexityApi = (userId: string | number) =>
  axios({
    method: "GET",
    url: `${apiDashboardSuffix}/addresscomplexity/${userId}`,
  });

export const fetchDashboardLastInvoiceApi = (userId: string | number) =>
  axios({
    method: "GET",
    url: `${apiDashboardSuffix}/lastinvoice/${userId}`,
  });

export const fetchConsumptionApi = (userId: string | number) =>
  axios({
    method: "GET",
    url: `${apiDashboardSuffix}/consumption/${userId}`,
  });

export const fetchDashboardMultimediaContractApi = (userId: string | number) =>
  axios({
    method: "GET",
    url: `${apiDashboardSuffix}/multimediacontractinfo/${userId}`,
  });

export const fetchDashboardElectricityContractApi = (userId: string | number) =>
  axios({
    method: "GET",
    url: `${apiDashboardSuffix}/electricitycontractinfo/${userId}`,
  });

export const subscribeToNewsletterApi = (
  userId: string | number,
  data: subscribeToNewsletterRequest
) =>
  axios({
    method: "POST",
    url: `${apiDashboardSuffix}/newslettersubscribe/${userId}`,
    data,
  });
