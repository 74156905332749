/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppState } from "../../../../redux/store";

export const isComplexDashboardSelector = (state: AppState) =>
  state.dashboard["isComplex"];
export const lastInvoiceDashboardSelector = (state: AppState) =>
  state.dashboard["lastInvoice"];
export const consumptionDashboardSelector = (state: AppState) =>
  state.dashboard["consumption"];
export const multimediaContractDashboardSelector = (state: AppState) =>
  state.dashboard["multimediaContract"];
export const electricityContractDashboardSelector = (state: AppState) =>
  state.dashboard["electricityContract"];
export const isFetchingLastInvoiceAndElectricityContractSelector = (
  state: AppState
) => state.dashboard["isFetchingLastInvoiceAndElectricityContract"];
export const isFetchingDashboardSelector = (state: AppState) =>
  state.dashboard["isFetching"];
export const isSubmittingDashboardSelector = (state: AppState) =>
  state.dashboard["isSubmitting"];
