/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */
/* eslint-disable no-shadow */

import { useEffect, useState } from "react";
import { SearchIcon } from "../../../assets/icons";
import { PAGINATION } from "../../../common/constants/pagination";
import {
  dateOnly,
  formatDate,
  subtractDate,
  subtractDateBy,
} from "../../../common/helpers/dates";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import {
  Col,
  Input,
  Row,
  Separator,
  Table,
  Title,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import styles from "./MeterReadings.module.scss";
import {
  allAddressesUnchecked,
  fetchMeterReadingsHistory,
} from "./service/meterReadings.actions";
import {
  limitAddresses,
  searchAddresses,
} from "./service/meterReadings.helpers";
import {
  isComplexMeterReadingsSelector,
  isFetchingMeterReadingsHistorySelector,
  meterReadingsAddressesSelector,
  meterReadingsHistorySelector,
  totalResultsMeterReadingsHistorySelector,
} from "./service/meterReadings.selectors";

const MeterReadingsTables = () => {
  const meterReadingsHistoryColumns = [
    { dataIndex: "dateTimeOfReading", title: "Date du relevé" },
    { dataIndex: "meterId", title: "Numéro de point de mesure" },
    { dataIndex: "cadran", title: "Cadran" },
    { dataIndex: "consumptionInkWh", title: "Relevé  (kWh / kVarh / kW)" },
    // { dataIndex: 'motifDuReleve', title: 'Motif du relevé' }
  ];
  const dispatch = useAppDispatch();
  const addresses = useAppSelector(meterReadingsAddressesSelector);
  const meterReadingsHistory = useAppSelector(meterReadingsHistorySelector);
  const isComplex = useAppSelector(isComplexMeterReadingsSelector);
  const isFetchingHistory = useAppSelector(
    isFetchingMeterReadingsHistorySelector
  );
  const totalResultsMeterReadingsHistory = useAppSelector(
    totalResultsMeterReadingsHistorySelector
  );
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [addressSearchValue, setAddressSearchValue] = useState("");
  const [dateRanges, setDateRanges] = useState([
    subtractDateBy(dateOnly(), "year", 1),
    dateOnly(),
  ]);
  const [dateRangesError, setDateRangesError] = useState("");
  const [checkedAddresses, setCheckedAddresses] = useState([
    { addressId: "ALL", checked: false },
  ]);

  const [currentSort, setCurrentSort] = useState("");
  // const addressesIds = checkedAddresses
  //   .filter(address => address.checked && address.addressId !== 'ALL')
  //   .map(address => address.addressId);
  const addressesIds = checkedAddresses.reduce((list: string[], option) => {
    if (option.checked && option.addressId !== "ALL") {
      const checkedAddress = addresses.find(
        (address) => address.addressId === option.addressId
      ) || { pointTheMeasure: null, listOfCountersAndInstallations: [] };
      return [...list, checkedAddress];
    }
    return list;
  }, []);

  const addressesColumns = [
    {
      dataIndex: "address",
      title: "Adresses",
      checked: checkedAddresses[0].checked,
    },
    { dataIndex: "meterId", title: "Numéro du point de mesure" },
    { dataIndex: "compteur", title: "Compteur" },
  ];

  const getDataForAddressesTable = (addressesData) =>
    addressesData.map((address) => ({
      key: address.addressId,
      address: address.address,
      meterId: address.pointTheMeasure,
      // installations: meterReadingsHistory.map(({ meterId }) => (meterId)).join(', '),
      // installations: address.listOfCountersAndInstallations?.map(counterAndInstallation => (
      // <div key={counterAndInstallation.installationId}>{counterAndInstallation.installationId}</div>)),
      counters: address.listOfCountersAndInstallations?.map(
        (counterAndInstallation) => (
          <div key={counterAndInstallation.counterId}>
            {counterAndInstallation.counterId}
          </div>
        )
      ),
    }));

  const sortingData = (meterReadingsHistory) => {
    if (meterReadingsHistory) {
      console.log("meterReadingOnSorting:", meterReadingsHistory);
      const sortedReadings = [...meterReadingsHistory].sort((a, b) => {
        const dateA = new Date(a.dateTimeOfReading);
        const dateB = new Date(b.dateTimeOfReading);
        return dateB.getTime() - dateA.getTime();
      });
      console.log("sortedReadings:", sortedReadings);
      return sortedReadings;
    }
    return meterReadingsHistory;
  };

  const sortedDataForMeterReadingsHistoryTable = sortingData(
    meterReadingsHistory
  ).map(
    (
      { dateTimeOfReading, meterId, cadran, consumptionInkWh, motifDuReleve },
      index
    ) => ({
      key: index,
      dateTimeOfReading: formatDate(new Date(dateTimeOfReading), "dd.mm.yyyy"),
      meterId,
      cadran,
      consumptionInkWh,
      motifDuReleve,
    })
  );

  // const dataForMeterReadingsHistoryTable = meterReadingsHistory.map(
  //   (
  //     { dateTimeOfReading, meterId, cadran, consumptionInkWh, motifDuReleve },
  //     index
  //   ) => ({
  //     key: index,
  //     dateTimeOfReading: formatDate(new Date(dateTimeOfReading), "dd.mm.yyyy"),
  //     meterId,
  //     cadran,
  //     consumptionInkWh,
  //     motifDuReleve,
  //   })
  // );

  const totalResultsAddresses = () =>
    addresses.length - limitAddresses(addresses).length;
  // const totalResultsAddresses = () => {
  //   let total = 0;
  //   addresses.forEach(address => {
  //     if (address.listOfCountersAndInstallations) {
  //       total += address.listOfCountersAndInstallations.map(item => item.counterId).length;
  //     }
  //   });
  //   return total;
  // };

  const formatAddressesData = (addressesData) => {
    console.log("meterReadingsHistory: ", meterReadingsHistory);
    console.log(
      "sorted:",
      meterReadingsHistory ? sortingData(meterReadingsHistory) : "null values"
    );
    console.log("addressesData: ", addressesData);
    return addressesData.map((address) => ({
      ...address,
      checked: checkedAddresses.find(
        (addressItem) => addressItem.addressId === address.key
      )?.checked,
    }));
  };

  // when addresses are fetched, set new state for addresses checkboxes
  useEffect(() => {
    setCheckedAddresses([
      { addressId: "ALL", checked: false },
      ...addresses.map((address) => ({
        addressId: address.addressId,
        checked: false,
      })),
    ]);
    console.log("addressesArray: ", addresses);
  }, [addresses]);

  // fetch new meter readings history on new checked addresses and on date change
  useEffect(() => {
    if (addresses.length && isComplex === false) {
      if (dateRanges[0] <= dateRanges[1]) {
        dispatch(
          fetchMeterReadingsHistory({
            take: PAGINATION.METER_READINGS_PER_PAGE,
            skip: 0,
            meterInstallations: [
              {
                meterId: addresses[0].pointTheMeasure,
                installationIds:
                  addresses[0].listOfCountersAndInstallations.map(
                    (countersAndInstallation) =>
                      countersAndInstallation.installationId.toString()
                  ),
              },
            ],
            startDate: dateRanges[0],
            endDate: dateRanges[1],
          })
        );
      }
    } else if (addressesIds.length) {
      if (dateRanges[0] <= dateRanges[1]) {
        dispatch(
          fetchMeterReadingsHistory({
            take: PAGINATION.METER_READINGS_PER_PAGE,
            skip: 0,
            meterInstallations: addressesIds.map((entry: any) => ({
              meterId: entry.pointTheMeasure,
              installationIds: entry.listOfCountersAndInstallations.map(
                (countersAndInstallation) =>
                  countersAndInstallation.installationId.toString()
              ),
            })),
            startDate: dateRanges[0],
            endDate: dateRanges[1],
          })
        );
      }
    } else {
      setCurrentSort("");
      dispatch(allAddressesUnchecked());
    }
    if (dateRanges[0] <= dateRanges[1]) {
      setDateRangesError("");
    } else {
      setDateRangesError(
        "La date de début doit être antérieure à la date de fin"
      );
    }
  }, [checkedAddresses, dateRanges]);

  return (
    <Col hasPageContentOffset span={12}>
      <Row justify="start">
        <Col span={12}>
          <Title level={2}>Mon historique de relevés</Title>
          {isComplex && (
            <Title className={styles.selectMeterReadingTitle} level={3}>
              Sélectionner vos lieux de consommation
            </Title>
          )}
        </Col>
        <Col lg={6} md={8} span={12}>
          <Input
            disabled={addressesIds.length > 0}
            placeholder="Recherche par adresse, n° de compteur, n° d’installation ..."
            prefix={<SearchIcon />}
            onChange={(event) => setAddressSearchValue(event.target.value)}
            onFocus={trackClickHandler}
          />
        </Col>
      </Row>
      <Separator />
      {isComplex && (
        <>
          <Table
            checkableFirstColumn
            checkableRows
            columns={addressesColumns}
            data={formatAddressesData(
              showAllAddresses
                ? getDataForAddressesTable(
                    searchAddresses(addresses, addressSearchValue)
                  )
                : getDataForAddressesTable(
                    limitAddresses(searchAddresses(addresses, addressSearchValue))
                  )
            )}
            hasShowAllButton={
              !showAllAddresses &&
              addresses.length !== limitAddresses(addresses).length
            }
            id="meterReadingsAddresses"
            totalResults={totalResultsAddresses()}
            onColumnChecked={(e) => {
              trackClickHandler();
              setCheckedAddresses(
                checkedAddresses.map((address) => ({
                  ...address,
                  checked: e.target.checked,
                }))
              );
            }}
            onRowChecked={(e) => {
              trackClickHandler();
              setCheckedAddresses([
                { addressId: "ALL", checked: false },
                ...checkedAddresses
                  .map((address) => ({
                    ...address,
                    checked:
                      +e.target.name === +address.addressId
                        ? e.target.checked
                        : address.checked,
                  }))
                  .slice(1),
              ]);
            }}
            onShowAllResults={() => {
              trackClickHandler();
              setShowAllAddresses(true);
            }}
          />
          <Separator />
          <Separator />
        </>
      )}
      <Row align="start" justify="start">
        <Col className={styles.datePickerFrom} lg={2} md={4} sm={5}>
          <Input
            defaultValue={subtractDate(new Date(), "input", "year", 1)}
            error={dateRangesError}
            label="Période du :"
            min={subtractDate(new Date(), "input", "year", 2)}
            type="date"
            onChange={(event) => {
              trackClickHandler();
              setDateRanges([new Date(event.target.value), dateRanges[1]]);
            }}
          />
        </Col>
        <Col lg={2} md={4} sm={5}>
          <Input
            defaultValue={formatDate(new Date(), "input")}
            label="au :"
            type="date"
            onChange={(event) => {
              trackClickHandler();
              setDateRanges([dateRanges[0], new Date(event.target.value)]);
            }}
          />
        </Col>
      </Row>
      <Separator />

      <Table
        pagination
        columns={meterReadingsHistoryColumns}
        currentPage={currentPage}
        data={
          checkedAddresses.length > 0 || !isComplex
            ? sortedDataForMeterReadingsHistoryTable
            : []
        }
        id="meterReadingsHistory"
        loading={isFetchingHistory}
        noDataMessage="Aucune donnée disponible."
        totalPages={Math.ceil(
          totalResultsMeterReadingsHistory / PAGINATION.METER_READINGS_PER_PAGE
        )}
        onPageChange={(page) => {
          dispatch(
            fetchMeterReadingsHistory({
              take: PAGINATION.METER_READINGS_PER_PAGE,
              skip: (page - 1) * PAGINATION.METER_READINGS_PER_PAGE,
              addressId: addressesIds,
              startDate: dateRanges[0],
              endDate: dateRanges[1],
            })
          );
          setCurrentPage(page);
        }}
      />
    </Col>
  );
};

export default MeterReadingsTables;
