/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

export const SIGN_UP_START = "PUBLIC::SIGN_UP_START";
export const SIGN_UP_SUCCESS = "PUBLIC::SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "PUBLIC::SIGN_UP_FAILURE";

export const SIGN_IN_START = "PUBLIC::SIGN_IN_START";
export const SIGN_IN_SUCCESS = "PUBLIC::SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "PUBLIC::SIGN_IN_FAILURE";

export const FORGOTTEN_PASSWORD_START = "PUBLIC::FORGOTTEN_PASSWORD_START";
export const FORGOTTEN_PASSWORD_SUCCESS = "PUBLIC::FORGOTTEN_PASSWORD_SUCCESS";
export const FORGOTTEN_PASSWORD_FAILURE = "PUBLIC::FORGOTTEN_PASSWORD_FAILURE";

export const RESET_PASSWORD_START = "PUBLIC::RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "PUBLIC::RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "PUBLIC::RESET_PASSWORD_FAILURE";
