/* eslint-disable linebreak-style */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { AxiosResponse } from "axios";
import { AppDispatch, AppState } from "../../../../../redux/store";
import { userSelector } from "../../../auth.selectors";
import { fetchPostalCodesApi } from "../../../../../api/postalCodes.api";
import { fetchAddressListResponseData } from "./addressForms.types";
import * as types from "./addressForms.actionTypes";

export const fetchPostalCodes =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_POSTAL_CODES_START });

      const response: AxiosResponse<fetchAddressListResponseData> =
        await fetchPostalCodesApi(id);

      dispatch({
        type: types.FETCH_POSTAL_CODES_SUCCESS,
        payload: {
          postalcodes: response.data.data,
        },
      });
    } catch (error) {
      dispatch({ type: types.FETCH_POSTAL_CODES_FAILURE });
    }
  };
