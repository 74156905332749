/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */

import { useEffect, useState } from "react";
import { PAGES } from "../../../common/constants/common";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import {
  Text,
  Title,
  Dropdown,
  Radio,
  Loader,
  Col,
  Row,
  Separator,
  Link,
} from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  fetchInvoiceReceptionMode,
  fetchInvoicesAddresses,
} from "../auth.actions";
import {
  invoicesAddressesSelector,
  isFetchingReceptionModeSelector,
  receptionModeSelector,
} from "../auth.selectors";
import styles from "./MyProfile.module.scss";

const MyProfileInvoiceReception = () => {
  const dispatch = useAppDispatch();
  const invoicesAddresses = useAppSelector(invoicesAddressesSelector);
  const receptionMode = useAppSelector(receptionModeSelector);

  const isFetching = useAppSelector(isFetchingReceptionModeSelector);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const onAddressChange = (e) => {
    const newSelectedAddress = invoicesAddresses?.find(
      (address) => address.address === e.target.value
    );
    if (newSelectedAddress) {
      setSelectedAddress(newSelectedAddress);
      dispatch(fetchInvoiceReceptionMode(newSelectedAddress.objektId));
    }
  };

  useEffect(() => {
    dispatch(fetchInvoicesAddresses());
  }, []);

  if (!invoicesAddresses) {
    return <Loader type="content" />;
  }

  return (
    <Row justify="start">
      <Col isContentBox className={styles.invoiceReception} span={12}>
        <Title level={3}>Informations de paiement</Title>
        <Dropdown
          className={styles.receptionDropdown}
          id="invoiceReceptionDropdown"
          name="invoiceReceptionDropdown"
          options={invoicesAddresses?.map((address) => address.address) || []}
          placeholder="Sélectionner l'adresse"
          onChange={onAddressChange}
        />
        {isFetching && <Loader type="content" />}
        {receptionMode && selectedAddress && !isFetching && (
          <>
            <Separator />
            <Text>Mode de réception de vos factures :</Text>
            <Separator />
            <Row>
              <Radio
                disabled
                className={styles.receptionModeRadio}
                defaultChecked={receptionMode?.email}
                id="email"
                name="invoiceReception"
                onChange={trackClickHandler}
              >
                Email
              </Radio>
              <Radio
                disabled
                className={styles.receptionModeRadio}
                defaultChecked={receptionMode?.courrier}
                id="courrier"
                name="invoiceReception"
                onChange={trackClickHandler}
              >
                Courrier
              </Radio>
              <Radio
                disabled
                className={styles.receptionModeRadio}
                defaultChecked={receptionMode?.ebanking}
                id="ebanking"
                name="invoiceReception"
                onChange={trackClickHandler}
              >
                eBill
              </Radio>
            </Row>
          </>
        )}
        <Separator />
        <Link to={PAGES.CONTACT_AND_FORMS} onClick={trackClickHandler}>
          Modifier mon mode de facturation
        </Link>
      </Col>
    </Row>
  );
};

export default MyProfileInvoiceReception;
