/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { contactAndFormsApi } from "../../../../api/contactAndForms.api";
import { AppDispatch, AppState } from "../../../../redux/store";
import { toastError, toastSuccess } from "../../../../services";
import { userSelector } from "../../../auth/auth.selectors";
import * as types from "./contactAndForms.actionTypes";
import { IContactData } from "./contactAndForms.types";

export const submitForm =
  (data: IContactData, resetForm: Function) =>
  async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.CONTACT_START });

      await contactAndFormsApi(id, data);

      dispatch({ type: types.CONTACT_SUCCESS });
      toastSuccess({ message: "Votre demande a été envoyée" });
      resetForm();
    } catch (error) {
      dispatch({ type: types.CONTACT_FAILURE });
      toastError({
        message: "Votre demande n'a pas pu être envoyée. Veuillez réessayer.",
      });
    }
  };
