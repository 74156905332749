/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { utils, writeFile } from "xlsx";
import { NAVIGATION_ITEMS } from "../../components/organisms/navigation/navigation.config";
import { PRIMARY_COLORS } from "../constants/common";

type changeAppPrimaryColorParams = {
  pathname?: string;
  color?: string;
};

export const changeAppPrimaryColor = ({
  pathname,
  color,
}: changeAppPrimaryColorParams) => {
  if (color) {
    document?.documentElement?.style.setProperty("--primary-color", color);
  } else {
    const newPrimaryColor = NAVIGATION_ITEMS.find(
      (item) => item.pathname === pathname
    )?.color;
    document?.documentElement?.style.setProperty(
      "--primary-color",
      newPrimaryColor || PRIMARY_COLORS.ELECTRICITY_AND_MULTIMEDIA
    );
  }
};

export const downloadCsvFile = (data, fileName) => {
  const BOM = "\uFEFF";
  let csvContent = "data:text/csv;charset=utf-8,";

  csvContent = data
    .map((row) =>
      row
        .map(String)
        .map((rowItem) => rowItem.replaceAll('"', '""'))
        .map((rowItem) => `"${rowItem}"`)
        .join(",")
    )
    .join("\r\n");

  const blob = new Blob([BOM + csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.click();
  link.remove();
};

export const downloadXlsxFile = (data, fileName) => {
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet(data);

  if (!ws["!cols"]) ws["!cols"] = [];
  ws["!cols"][0] = { width: 18 };

  utils.book_append_sheet(wb, ws, "SheetJS");
  writeFile(wb, `${fileName}.xlsx`, { cellStyles: true });
};

export const roundNumber = (number: number) => Math.round(number * 100) / 100;

export const downloadFromResp = (resp) => {
  const filenameArg = "filename=";
  const contentDisposition = resp.headers["content-disposition"];
  const startOfFilename = contentDisposition.indexOf(filenameArg) + filenameArg.length;
  const filename = contentDisposition.slice(startOfFilename);

  const blob = new Blob([resp.data], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.click();
  link.remove();
};
