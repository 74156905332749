/* eslint-disable linebreak-style */
import axios from './http';

const apiInvoicesSuffix = '/info';

export const fetchInvoicesAddressesApi = (userId) => axios({
  method: 'GET',
  url: `${apiInvoicesSuffix}/invoiceaddress/${userId}`,
});

export const fetchInvoicesHistoryApi = (userId, params) => axios({
  method: 'POST',
  url: `${apiInvoicesSuffix}/invoices/${userId}`,
  data: params,
});

export const fetchLatestInvoiceApi = (userId) => axios({
  method: 'GET',
  url: `${apiInvoicesSuffix}/lastinvoice/${userId}`,
});

export const fetchInvoiceReceptionApi = (userId) => axios({
  method: 'GET',
  url: `${apiInvoicesSuffix}/invoicereception/${userId}`,
});

export const downloadInvoicesPdfApi = (userId, data = {}) => axios({
  method: 'POST',
  url: `${apiInvoicesSuffix}/pdfs/${userId}`,
  data,
  responseType: 'arraybuffer'
});
