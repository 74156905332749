/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppAction } from "../../../../redux/actionType";
import * as types from "./myMultimediaOffer.actionTypes";

const initialState = {
  multimediaOffer: [],
  externalClient: null,
  oto: null,
  isFetching: false,
  isSubmitting: false,
};

// eslint-disable-next-line
export default (state = initialState, action: AppAction) => {
  switch (action?.type) {
    case types.FETCH_MULTIMEDIA_OFFER_START:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_MULTIMEDIA_OFFER_SUCCESS:
      return {
        ...state,
        multimediaOffer: action.payload.multimediaOffer,
        externalClient: action.payload.externalClient,
        oto: action.payload.oto,
        isFetching: false,
      };
    case types.FETCH_MULTIMEDIA_OFFER_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return { ...state };
  }
};
