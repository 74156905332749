/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Navigation from "../navigation/Navigation";
import { NAVIGATION_ITEMS } from "../navigation/navigation.config";
import { Row } from "../../ui";
import styles from "./PageLayout.module.scss";
import { PRIMARY_COLORS } from "../../../common/constants/common";
import PinkRectangleImage from "../../../assets/images/rectangle-pink.svg";
import BlueRectangleImage from "../../../assets/images/rectangle-blue.svg";

const cx = classNames.bind(styles);

type PageLayoutProps = {
  type?: "public" | "private" | "admin";
  hasWhiteTransparentBackground?: boolean;
  content?: ReactNode;
};

const PageLayout = ({
  type = "private",
  hasWhiteTransparentBackground = false,
  content,
}: PageLayoutProps) => {
  const [mobileNavigationOpened, setMobileNavigationOpened] = useState(false);
  const { pathname } = useLocation();
  const [privatePageBackgroundTheme, setPrivatePageBackgroundTheme] =
    useState("");

  useEffect(() => {
    const rootDiv = document.getElementById("root");
    rootDiv?.classList.add(
      type === "public" ? styles.publicPageLayout : styles.privatePageLayout
    );
    rootDiv?.classList.remove(
      type === "public" ? styles.privatePageLayout : styles.publicPageLayout
    );
  }, []);

  useEffect(() => {
    const currentPage = NAVIGATION_ITEMS.find(
      (navigationItem) => navigationItem.pathname === pathname
    );
    if (currentPage) {
      setPrivatePageBackgroundTheme(currentPage.color);
    } else {
      setPrivatePageBackgroundTheme(PRIMARY_COLORS.ELECTRICITY_AND_MULTIMEDIA);
    }
  }, [pathname]);

  const publicPageLayout = (
    <>
      <Header
        className={cx({
          [styles.whiteTransparentBackground]: hasWhiteTransparentBackground,
        })}
        type={type}
      />
      <main
        className={cx(styles.publicPageLayoutContent, {
          [styles.whiteTransparentBackground]: hasWhiteTransparentBackground,
        })}
      >
        {content}
      </main>
      <Footer />
    </>
  );

  const privatePageLayout = (
    <>
      {/* RECTANGLE SHAPES */}
      {privatePageBackgroundTheme !== PRIMARY_COLORS.MULTIMEDIA && (
        <img
          alt="pink-rectangle"
          className={styles.pinkRectangle}
          src={PinkRectangleImage}
        />
      )}
      {privatePageBackgroundTheme !== PRIMARY_COLORS.ELECTRICITY && (
        <img
          alt="blue-rectangle"
          className={styles.blueRectangle}
          src={BlueRectangleImage}
        />
      )}

      <Navigation
        mobileNavigationOpened={mobileNavigationOpened}
        type={type}
        onTogleMobileNavigation={() => setMobileNavigationOpened(false)}
      />
      <Row
        className={styles.privatePageHeaderAndContent}
        direction="column"
        justify="start"
      >
        <Header
          type={type}
          onToggleMobileNavigation={() =>
            setMobileNavigationOpened(!mobileNavigationOpened)
          }
        />
        <main className={cx(styles.privatePageLayoutContent)}>{content}</main>
      </Row>
      <Footer />
    </>
  );

  return type === "public" ? publicPageLayout : privatePageLayout;
};

export default PageLayout;
