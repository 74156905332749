/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-sort-props */
import classNames from "classnames/bind";
import { MouseEventHandler, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CheckIcon, CloseIcon } from "../../../../assets/icons";
import trackClickHandler from "../../../../common/helpers/trackClickHandler";
import {
  Button,
  Col,
  DropdownWithSearch,
  Input,
  Row,
  Title,
} from "../../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateAddress } from "../../auth.actions";
import { myProfileSelector } from "../../auth.selectors";
import styles from "../MyProfileForms.module.scss";
import { fetchPostalCodes } from "./service/addressForms.actions";
import { postalCodesSelector } from "./service/addressForms.selectors";

const cx = classNames.bind(styles);

type AddressesFormProps = {
  isEditing?: boolean;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: Function;
};

const AddressesForm = ({
  isEditing = false,
  onEdit = () => {},
  onCancel = () => {},
}: AddressesFormProps) => {
  const myProfile = useAppSelector(myProfileSelector);
  const postalCodes = useAppSelector(postalCodesSelector);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      street: myProfile?.address?.street ?? "",
      houseNumber: myProfile?.address?.houseNumber ?? "",
      postCodeId: myProfile?.address?.postalCodeId ?? "",
    },
    shouldUnregister: true,
  });

  useEffect(() => {
    dispatch(fetchPostalCodes());
  }, []);

  const onSubmit = (data) => {
    dispatch(
      updateAddress({
        data: { ...data, addressId: myProfile?.address?.id },
        callback: onCancel,
      })
    );
  };

  return (
    <Row align="start" direction="column">
      <Title level={3}>
        Adresse de contact&nbsp;<span className={styles.redDot}>*</span>
      </Title>
      <Col span={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row wrapForMobile align="end" justify="space-between">
            <Col className={styles.formRowSpaceBottom} md={8} span={12}>
              <Input
                {...register("street", { required: true })}
                label="Rue"
                readOnly={!isEditing}
                onFocus={trackClickHandler}
              />
            </Col>
            <Col
              className={cx(styles.formRowSpaceBottom, styles.formRowSpaceLeft)}
              md={3}
              span={2}
            >
              <Input
                {...register("houseNumber", { required: true })}
                label="N&deg;"
                readOnly={!isEditing}
                onFocus={trackClickHandler}
              />
            </Col>
          </Row>
          <Row wrapForMobile align="end" justify="space-between">
            <Col className={styles.formRowSpaceBottom} md={12} span={12}>
              <DropdownWithSearch
                className={styles.receptionDropdown}
                defaultValue={myProfile.address.postalCodeCityName}
                id="postCodeId"
                label="NPA & Localité"
                name="city"
                optionLabel="value"
                options={postalCodes || []}
                optionValue="postalCodeId"
                placeholder="Sélectionner l'adresse"
                readOnly={!isEditing}
                onChange={(newValue) => setValue("postCodeId", newValue)}
              />
            </Col>
          </Row>
          {!isEditing && (
            <Row justify="end">
              <Button onClick={onEdit}>Éditer</Button>
            </Row>
          )}
          {isEditing && (
            <Row className={styles.editButtons} justify="end">
              <Button
                className={styles.closeIcon}
                type="button"
                onClick={() => {
                  reset();
                  onCancel();
                }}
              >
                <CloseIcon />
              </Button>
              <Button
                className={styles.checkIcon}
                type="submit"
                onClick={trackClickHandler}
              >
                <CheckIcon />
              </Button>
            </Row>
          )}
        </form>
      </Col>
    </Row>
  );
};

export default AddressesForm;
