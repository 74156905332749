/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AxiosResponse } from "axios";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import React, { Component, useCallback, useState, useEffect } from "react";
import classNames from "classnames/bind";
import { StarRoundedIcon } from "../../../assets/icons";
import { Col, Button, Row, Separator, Text, Title } from "../../../components/ui";
import { validateAccountApi } from "../../../api/public.api";
import { confirmEmailData } from "../public.types";
import { PAGES, SPECIAL_CHARACTERS } from '../../../common/constants/common';
import styles from "./SignUpValidation.module.scss";
import openLink from "../../../common/helpers/openLink";

const cx = classNames.bind(styles);

const SignUpValidation = () => {
    const [message, setTextMessage] = useState('Votre compte est en cours de validation...');
    const [info, setTextInfo] = useState("Merci de patienter quelques instants !");
    const [hidden, setHidden] = useState(true);
    const { search } = useLocation();
    const history = useHistory();
    const urlParams = new URLSearchParams(search);
    /* const { email, token } = useParams(); */
    const validateAccount = async () => {
        try {
            const email = urlParams.get('email');
            const token = urlParams.get('token');
            const response: AxiosResponse<confirmEmailData> = await validateAccountApi(email, token);
            if (response.data.isSuccess) {
                setTimeout(() => {
                    setHidden(false);
                    setTextMessage('Votre compte a bien été activé.');
                    setTextInfo("Veuillez cliquer sur le bouton ci-dessous pour vous connecter.");
                  }, 3000);
            }
          } catch (error) {
            setTimeout(() => {
                setHidden(true);
                setTextMessage("Une erreur s'est produite !");
                setTextInfo("Veuillez recommencer l'opération plus tard, si le problème persiste, contactez le support sur <a href='mailto:support@sie.ch'>Support SIE</a>");
              }, 3000);
          }
    };

    useEffect(() => {
        validateAccount();
    }, []);

    return (
      <Row direction="column">
        <Col className="position-relative" lg={3} md={5} sm={8} span={10}>
          <Title align="center" level={1}>
            { message }
          </Title>

          {/* shapes */}
          <div className={cx(styles.smallCircle, styles.smallCircleTopLeft)} />
          <div className={cx(styles.line, styles.lineTopLeft)} />
          <div className={cx(styles.bigCircle, styles.bigCircleTopLeft)} />
          <div className={cx(styles.smallCircle, styles.smallCircleTopLeft2)} />
          <div className={cx(styles.line, styles.lineTopRight)} />
          <div className={cx(styles.smallCircle, styles.smallCircleTopRight)} />
          <StarRoundedIcon className={cx(styles.star, styles.starTopRight)} />
          <div className={cx(styles.smallCircle, styles.smallCircleTopRight2)} />
        </Col>
        <Col className="position-relative" lg={3} md={5} sm={8} span={10}>
          <Text align="center" size="large">
            <span dangerouslySetInnerHTML={{ __html: info }} />
          </Text>
          <Separator size="large" />
          <div hidden={hidden}>
            <Row align="center">
              <Button
                className={styles.subscribeButton}
                type="button"
                onClick={() => openLink(PAGES.SIGN_IN)}
              >
                Connectez-vous
              </Button>
            </Row>
          </div>

          {/* shapes */}
          <StarRoundedIcon className={cx(styles.star, styles.starBottomLeft)} />
          <div className={cx(styles.line, styles.lineBottomLeft)} />
          <div className={cx(styles.line, styles.lineBottomRight)} />
          <div className={cx(styles.bigCircle, styles.bigCircleBottomRight)} />
        </Col>
      </Row>
    );
};

export default SignUpValidation;
