/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import axios from "./http";

const apiInfoSuffix = "/info";

export const fetchPostalCodesApi = (userId: string) =>
  axios({
    method: "GET",
    url: `${apiInfoSuffix}/postalcodes/${userId}`,
  });
