/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable quotes */
import classNames from "classnames/bind";
import facebookSIELogo from "../../../assets/images/facebook-sie.png";
import facebookTVTLogo from "../../../assets/images/facebook-tvt.png";
import linkedinSIELogo from "../../../assets/images/linkedin-sie.png";
import linkedinTVTLogo from "../../../assets/images/linkedin-tvt.png";
import youTubeSIELogo from "../../../assets/images/youtube-sie.png";
import youTubeTVTLogo from "../../../assets/images/youtube-tvt.png";
import { PAGES } from "../../../common/constants/common";
import footerClicksTracker from "../../../common/helpers/trackFooterHandler";
import { Col, Row } from "../../ui";
import FooterContact from "../../ui/footerContact/FooterContact";
import Link from "../../ui/link/Link";
import Title from "../../ui/title/Title";
import styles from "./Footer.module.scss";

const cx = classNames.bind(styles);

type FooterProps = { className?: string };

const Footer = ({ className = "" }: FooterProps) => (
  <footer className={cx(styles.footer, className)}>
    <Row align="start">
      <Col md={3} span={8}>
        <Title align="center" className={styles.footerColumnTitle} level={3}>
          Nos réseaux sociaux
        </Title>
        <div className={styles.footerSocialIconsWrapper}>
          <div className={styles.footerSocialRed}>
            {/* FB red */}
            <Link
              className={styles.footerSocialMediaLink}
              href="https://www.facebook.com/ServiceIntercommunaldesEnergies"
              target="_blank"
              onClick={footerClicksTracker}
            >
              <img
                alt="SIE Facebook Logo"
                className={styles.footerSocialMediaLogo}
                src={facebookSIELogo}
                title="SIE Facebook Logo"
              />
            </Link>

            {/* Lkdn red */}
            <Link
              className={styles.footerSocialMediaLink}
              href="https://www.linkedin.com/company/sie-sa/"
              target="_blank"
              onClick={footerClicksTracker}
            >
              <img
                alt="SIE linkedIn Logo"
                className={styles.footerSocialMediaLogo}
                src={linkedinSIELogo}
                title="SIE linkedIn Logo"
              />
            </Link>

            {/* utb red */}
            <Link
              className={styles.footerSocialMediaLink}
              href="https://www.youtube.com/@sieserviceintercommunaldes6800"
              target="_blank"
              onClick={footerClicksTracker}
            >
              <img
                alt="SIE YouTube Logo"
                className={styles.footerSocialMediaLogo}
                src={youTubeSIELogo}
                title="SIE YouTube Logo"
              />
            </Link>
          </div>
          <div className={styles.footerSocialBlue}>
            {/* fb blue */}
            <Link
              className={styles.footerSocialMediaLink}
              href="https://www.facebook.com/TvtServicesSa/"
              target="_blank"
              onClick={footerClicksTracker}
            >
              <img
                alt="TVT Facebook Logo"
                className={styles.footerSocialMediaLogo}
                src={facebookTVTLogo}
                title="TVT Facebook Logo"
              />
            </Link>
            <Link
              className={styles.footerSocialMediaLink}
              href="https://www.linkedin.com/company/tvtservices/"
              target="_blank"
              onClick={footerClicksTracker}
            >
              <img
                alt="SIE linkedIn Logo"
                className={styles.footerSocialMediaLogo}
                src={linkedinTVTLogo}
                title="TVT linkedIn Logo"
              />
            </Link>

            {/* utb red */}
            <Link
              className={styles.footerSocialMediaLink}
              href="https://www.youtube.com/@tvtservicessa7527"
              target="_blank"
              onClick={footerClicksTracker}
            >
              <img
                alt="SIE YouTube Logo"
                className={styles.footerSocialMediaLogo}
                src={youTubeTVTLogo}
                title="TVT YouTube Logo"
              />
            </Link>
          </div>
        </div>
      </Col>
      <Col lg={4} md={5} span={10} xxl={3}>
        <Title align="center" className={styles.footerColumnTitle} level={3}>
          Nous contacter
        </Title>
        <div className={styles.footerContactWrapper}>
          <FooterContact
            contactTitle="SIE SA – Service Intercommunal des Énergies:"
            footerAddress="Rue Neuve 5"
            footerEmail="info@sie.ch"
            footerMunicipality="1020 Renens"
            footerTelephone="021 631 50 00"
          />

          <FooterContact
            contactTitle="TVT Services SA:"
            footerAddress="Rue Neuve 5"
            footerEmail="info@tvtservices.ch"
            footerMunicipality="1020 Renens"
            footerTelephone="021 631 50 00"
          />
        </div>
      </Col>
      <Col md={3} span={10}>
        <Title align="center" className={styles.footerColumnTitle} level={3}>
          Liens et annexes
        </Title>
        <Link
          className={styles.footerAnnexLink}
          href="https://www.sie.ch/actualites/"
          onClick={footerClicksTracker}
        >
          Nos actualités
        </Link>
        <Link
          className={styles.footerAnnexLink}
          href="https://www.sie.ch/"
          onClick={footerClicksTracker}
        >
          Site internet SIE
        </Link>
        <Link
          className={styles.footerAnnexLink}
          href="https://www.tvtservices.ch/"
          onClick={footerClicksTracker}
        >
          Site internet TVT Services
        </Link>
        <Link
          className={styles.footerAnnexLink}
          target="_blank"
          to={PAGES.DATA_PROTECTION}
          onClick={footerClicksTracker}
        >
          Charte de protection de vos données
        </Link>
        <Link
          className={styles.footerAnnexLink}
          target="_blank"
          to={PAGES.TERMS_OF_SERVICE}
          onClick={footerClicksTracker}
        >
          Conditions générales d’utilisation
        </Link>
      </Col>
    </Row>
  </footer>
);

export default Footer;
