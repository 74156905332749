/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

export const FETCH_USER_START = "AUTH::FETCH_USER_START";
export const FETCH_USER_SUCCESS = "AUTH::FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "AUTH::FETCH_USER_FAILURE";

export const FETCH_MY_PROFILE_DATA_START = "AUTH::FETCH_MY_PROFILE_DATA_START";
export const FETCH_MY_PROFILE_DATA_SUCCESS =
  "AUTH::FETCH_MY_PROFILE_DATA_SUCCESS";
export const FETCH_MY_PROFILE_DATA_FAILURE =
  "AUTH::FETCH_MY_PROFILE_DATA_FAILURE";

export const FETCH_MY_PROFILE_ELECTRICITY_OFFER_START =
  "AUTH::FETCH_MY_PROFILE_ELECTRICITY_OFFER_START";
export const FETCH_MY_PROFILE_ELECTRICITY_OFFER_SUCCESS =
  "AUTH::FETCH_MY_PROFILE_ELECTRICITY_OFFER_SUCCESS";
export const FETCH_MY_PROFILE_ELECTRICITY_OFFER_FAILURE =
  "AUTH::FETCH_MY_PROFILE_ELECTRICITY_OFFER_FAILURE";

export const UPDATE_MY_PROFILE_DATA_START =
  "AUTH::UPDATE_MY_PROFILE_DATA_START";
export const UPDATE_MY_PROFILE_DATA_SUCCESS =
  "AUTH::UPDATE_MY_PROFILE_DATA_SUCCESS";
export const UPDATE_MY_PROFILE_DATA_FAILURE =
  "AUTH::UPDATE_MY_PROFILE_DATA_FAILURE";

export const UPDATE_EMAIL_START = "AUTH::UPDATE_EMAIL_START";
export const UPDATE_EMAIL_SUCCESS = "AUTH::UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAILURE = "AUTH::UPDATE_EMAIL_FAILURE";

export const UPDATE_PASSWORD_START = "AUTH::UPDATE_PASSWORD_START";
export const UPDATE_PASSWORD_SUCCESS = "AUTH::UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "AUTH::UPDATE_PASSWORD_FAILURE";

export const FETCH_INVOICES_ADDRESSES_START =
  "AUTH::FETCH_INVOICES_ADDRESSES_START";
export const FETCH_INVOICES_ADDRESSES_SUCCESS =
  "AUTH::FETCH_INVOICES_ADDRESSES_SUCCESS";
export const FETCH_INVOICES_ADDRESSES_FAILURE =
  "AUTH::FETCH_INVOICES_ADDRESSES_FAILURE";

export const FETCH_INVOICE_ADDRESS_RECEPTION_MODE_START =
  "AUTH::FETCH_INVOICE_ADDRESS_RECEPTION_MODE_START";
export const FETCH_INVOICE_ADDRESS_RECEPTION_MODE_SUCCESS =
  "AUTH::FETCH_INVOICE_ADDRESS_RECEPTION_MODE_SUCCESS";
export const FETCH_INVOICE_ADDRESS_RECEPTION_MODE_FAILURE =
  "AUTH::FETCH_INVOICE_ADDRESS_RECEPTION_MODE_FAILURE";

export const SIGN_OUT = "AUTH::SIGN_OUT";
