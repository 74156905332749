/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
export const CONTACT_START = "CONTACT::CONTACT_START";
export const CONTACT_SUCCESS = "CONTACT::CONTACT_SUCCESS";
export const CONTACT_FAILURE = "CONTACT::CONTACT_FAILURE";
