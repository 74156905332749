/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { useForm } from "react-hook-form";
import { HomeIcon, PhoneIcon, PlugIcon } from "../../../assets/icons";
import { PAGES } from "../../../common/constants/common";
import trackClickHandler from "../../../common/helpers/trackClickHandler";
import openLinkInNewTab from "../../../common/helpers/openLinkInNewTab";
import { Button, Col, Input, Link, Row, Separator, Text, Title, } from "../../../components/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import styles from "./Dashboard.module.scss";
import { subscribeToNewsletter } from "./service/dashboard.actions";
import { isSubmittingDashboardSelector } from "./service/dashboard.selectors";
import { subscribeToNewsletterRequest } from "./service/dashboard.types";

const Newsletter = () => {
    const isSubmitting = useAppSelector(isSubmittingDashboardSelector);
    const dispatch = useAppDispatch();
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      defaultValues: {
        email: "",
      },
      shouldUnregister: true,
    });

    const onSubscribe = (data: subscribeToNewsletterRequest) => {
      dispatch(subscribeToNewsletter(data, reset));
    };

    return (
      <Row>
        <Col className={styles.newsletter} span={12}>
          <Title align="center" className={styles.subscribeText} level={2}>
            Inscription à la newsletter
          </Title>
          <Text align="center" className={styles.stayInTouch} size="large">Restez au courant de toutes nos actualités et de nos nouvelles offres.</Text>
          <Row align="center">
            <Button
              className={styles.subscribeButton}
              loading={isSubmitting}
              type="submit"
              onClick={() => openLinkInNewTab('https://www.sie.ch/newsletter')}
              >
              Abonnez-vous
            </Button>
          </Row>
        </Col>
      </Row>
    );
  };

  export default Newsletter;
