/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
import {
  fetchConsumptionAddressesApi,
  fetchConsumptionCsvApi,
  fetchConsumptionHistoryApi,
} from "../../../../api/consumption.api";
import { fetchConsumptionApi } from "../../../../api/dashboard.api";
import { CancelToken } from "../../../../api/http";
import { endOfDay, formatDate } from "../../../../common/helpers/dates";
import { downloadXlsxFile } from "../../../../common/helpers/helpers";
import { AppDispatch, AppState } from "../../../../redux/store";
import { toastError } from "../../../../services";
import { userSelector } from "../../../auth/auth.selectors";
import * as types from "./consumption.actionTypes";
import { createCsvData, getBarWidth } from "./consumption.helpers";

export const fetchConsumptionAndAddresses =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_CONSUMPTION_AND_ADDRESSES_START });

      const responseConsumption = await fetchConsumptionApi(id);
      const responseAddresses = await fetchConsumptionAddressesApi(id);

      dispatch({
        type: types.FETCH_CONSUMPTION_AND_ADDRESSES_SUCCESS,
        payload: {
          isComplex: responseAddresses.data.data.complexAddress,
          addresses: responseAddresses.data.data.consumptionAddress,
          consumption: responseConsumption.data.data,
        },
      });
    } catch (error) {
      dispatch({ type: types.FETCH_CONSUMPTION_AND_ADDRESSES_FAILURE });
    }
  };

let cancelConsumptionHistoryToken;
export const fetchConsumptionHistory =
  (data) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_CONSUMPTION_HISTORY_START });

      cancelConsumptionHistoryToken?.cancel("Abort previous...");
      cancelConsumptionHistoryToken = CancelToken.source();
      const response = await fetchConsumptionHistoryApi(
        id,
        {
          ...data,
          endDate: endOfDay(new Date(data.endDate)),
        },
        cancelConsumptionHistoryToken
          ? cancelConsumptionHistoryToken.token
          : null
      );

      dispatch({
        type: types.FETCH_CONSUMPTION_HISTORY_SUCCESS,
        payload: {
          history: response.data.data.history,
          totalConsumption: response.data.data.totalConsumption,
          barSize: getBarWidth(response.data.data.history.length),
          hasSolarPanelTarif: response.data.data.hasSolarPanelTarif,
          hasPermanentTarif: response.data.data.hasPermanentTarif,
        },
      });
    } catch (error: any) {
      if (!(error.code && error.code === "ERR_CANCELED")) {
        dispatch({ type: types.FETCH_CONSUMPTION_HISTORY_FAILURE });
        toastError({
          message: "Une erreur s'est produite, veuillez rafraichir la page.",
        });
      }
    }
  };

let cancelConsumptionHistoryForSecondChartToken;
export const fetchConsumptionHistoryForSecondChart =
  (data) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({
        type: types.FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_START,
      });

      cancelConsumptionHistoryForSecondChartToken?.cancel("Abort previous...");
      cancelConsumptionHistoryForSecondChartToken = CancelToken.source();
      const response = await fetchConsumptionHistoryApi(
        id,
        {
          ...data,
          endDate: endOfDay(new Date(data.endDate)),
        },
        cancelConsumptionHistoryForSecondChartToken
          ? cancelConsumptionHistoryForSecondChartToken.token
          : null
      );
      dispatch({
        type: types.FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_SUCCESS,
        payload: {
          history: response.data.data.history,
          totalConsumption: response.data.data.totalConsumption,
          barSize: getBarWidth(response.data.data.history.length),
          hasSolarPanelTarif: response.data.data.hasSolarPanelTarif,
          hasPermanentTarif: response.data.data.hasPermanentTarif,
        },
      });
    } catch (error: any) {
      if (!(error.code && error.code === "ERR_CANCELED")) {
        dispatch({
          type: types.FETCH_CONSUMPTION_HISTORY_FOR_SECOND_CHART_FAILURE,
        });
        toastError({
          message: "Une erreur s'est produite, veuillez rafraichir la page.",
        });
      }
    }
  };

export const downloadCSV =
  (data) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.DOWNLOAD_CSV_START });

      const response = await fetchConsumptionCsvApi(id, data);

      const compareDataExists =
        response.data.data?.consumptionComparerGraphCsvs?.length > 0;

      const columns = ["Date et heure"];
      if (response.data.data?.hasPermanentTarif) {
        columns.push("Permanent(tariff solo)");
      }
      if (response.data.data?.hasMixedTarif) {
        columns.push("Heures pleines");
        columns.push("Heures creuses");
      }
      if (response.data.data?.hasNetworkConsumptionTarif) {
        columns.push("Consommation du réseau");
      }
      if (response.data.data?.hasSelfConsumptionOfManagerTarif) {
        columns.push("Autoconsommation du RCP");
      }
      if (response.data.data?.hasTotalSelfConsumptionOfRCPPartnersTarif) {
        columns.push("Totalité autoconsommation des participants du RC");
      }
      if (response.data.data?.hasSolarPanelTarif) {
        columns.push("Injection sur le reseau");
      }
      columns.push("Unité");

      const firstChartCsv = createCsvData({
        columns,
        data: response.data.data.consumptionGraphCsvs,
        hasMixedTarif: response.data.data.hasMixedTarif,
        hasPermanentTarif: response.data.data.hasPermanentTarif,
        hasNetworkConsumptionTarif: response.data.data.hasNetworkConsumptionTarif,
        hasSelfConsumptionOfManagerTarif: response.data.data.hasSelfConsumptionOfManagerTarif,
        hasTotalSelfConsumptionOfRCPPartnersTarif: response.data.data.hasTotalSelfConsumptionOfRCPPartnersTarif,
        hasSolarPanelTarif: response.data.data.hasSolarPanelTarif,
      });
      const secondChartCsv = compareDataExists
        ? createCsvData({
            columns,
            data: response.data.data.consumptionComparerGraphCsvs,
            hasMixedTarif: response.data.data.hasMixedTarif,
            hasPermanentTarif: response.data.data.hasPermanentTarif,
            hasNetworkConsumptionTarif: response.data.data.hasNetworkConsumptionTarif,
            hasSelfConsumptionOfManagerTarif: response.data.data.hasSelfConsumptionOfManagerTarif,
            hasTotalSelfConsumptionOfRCPPartnersTarif: response.data.data.hasTotalSelfConsumptionOfRCPPartnersTarif,
            hasSolarPanelTarif: response.data.data.hasSolarPanelTarif,
          })
        : null;

      downloadXlsxFile(
        firstChartCsv,
        `Téléchargement_consommations${
          compareDataExists ? "_1" : ""
        }_${formatDate(new Date(), "dd-mm-yyyy-hh-mm-ss")}`
      );
      if (secondChartCsv) {
        downloadXlsxFile(
          secondChartCsv,
          `Téléchargement_consommations_2_${formatDate(
            new Date(),
            "dd-mm-yyyy-hh-mm-ss"
          )}`
        );
      }
      dispatch({ type: types.DOWNLOAD_CSV_SUCCESS });
    } catch (error) {
      dispatch({ type: types.DOWNLOAD_CSV_FAILURE });
    }
  };
