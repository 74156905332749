/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */
/* eslint-disable function-paren-newline */
import axios from "./http";

const apiInfoSuffix = "/info";

export const fetchUserApi = (userId: string) =>
  axios({
    method: "GET",
    url: `${apiInfoSuffix}/userInfo/${userId}`,
  });

export const fetchMyProfileAddressApi = (userId: string) =>
  axios({
    method: "GET",
    url: `${apiInfoSuffix}/address/${userId}`,
  });

export const fetchMyProfileContactApi = (userId: string) =>
  axios({
    method: "GET",
    url: `${apiInfoSuffix}/contact/${userId}`,
  });

export const fetchMyProfileMultimediaApi = (userId: string) =>
  axios({
    method: "GET",
    url: `${apiInfoSuffix}/multimedia/${userId}`,
  });

export const fetchMyProfileMultimediaContractApi = (userId: string) =>
  axios({
    method: "GET",
    url: `${apiInfoSuffix}/multimediacontractinfo/${userId}`,
  });

export const fetchMyProfileElectricityContractApi = (userId: string) =>
  axios({
    method: "GET",
    url: `${apiInfoSuffix}/electricitycontractinfo/${userId}`,
  });

export const updateAddressApi = (userId, data) =>
  axios({
    method: "PATCH",
    url: `${apiInfoSuffix}/address/${userId}`,
    data,
  });

export const updateMobileApi = (userId, data) =>
  axios({
    method: "PUT",
    url: `${apiInfoSuffix}/mobile/${userId}`,
    data,
  });

export const updateTelephoneApi = (userId, data) =>
  axios({
    method: "PUT",
    url: `${apiInfoSuffix}/telephone/${userId}`,
    data,
  });

export const updateEmailApi = (userId, data) =>
  axios({
    method: "PUT",
    url: `${apiInfoSuffix}/email/${userId}`,
    data,
  });

export const updatePasswordApi = (userId, data) =>
  axios({
    method: "PUT",
    url: `${apiInfoSuffix}/password/${userId}`,
    data,
  });

export const fetchInvoicesAddressesApi = (userId) =>
  axios({
    method: "GET",
    url: `${apiInfoSuffix}/invoicereceptionaddress/${userId}`,
  });

export const fetchInvoicesReceptionModeApi = (userId, objectId) =>
  axios({
    method: "GET",
    url: `${apiInfoSuffix}/invoicereception/${userId}?objectId=${objectId}`,
  });
