/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { Col, Row, Separator, Text } from "../../../components/ui";
import styles from "./ComingSoon.module.scss";

const ComingSoon = () => (
  <Row align="center" className={styles.comingSoon} direction="column">
    <Col className={styles.content} span={12} xl={9}>
      <Text size="large">
        Dans quelques jours, vous retrouverez sur cette page le portail «Mon
        Espace Client» de SIE et TVT.
      </Text>
      <Separator size="large" />
      <Text size="large">
        Mon Espace Client comprend le détail de vos offres énergie et
        multimédia, ainsi que des services comme les relevés de vos compteurs
        ainsi que l’annonce d’un emménagement/déménagement.
      </Text>
      <Separator size="large" />
      <Text size="large">
        Grâce à lui, vous gardez en tout temps une vue d’ensemble de votre
        consommation d’énergie ainsi que de vos factures SIE et TVT Services
        émises depuis le 1er janvier 2023, au format digital.
      </Text>
      <Separator size="large" />
      <Text size="large">
        Merci de vous reconnecter courant février 2023, dès réception de votre
        première facture SIE/TVT, pour profiter des avantages de ces services
        numériques.
      </Text>
      <Separator size="large" />
      <Text size="large">
        L’utilisation de votre Espace Client SIE/TVT nécessite un enregistrement
        unique. Il vous sera demandé de vous identifier grâce à votre nom et
        votre nouveau numéro de client, information que vous trouverez en haut à
        droite de vos futures factures SIE/TVT.
      </Text>
    </Col>
  </Row>
);

export default ComingSoon;
