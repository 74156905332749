/* eslint-disable linebreak-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { AppAction } from "../../../../../redux/actionType";
import * as types from "./addressForms.actionTypes";

const initialState = {
  postalcodes: [],
  isFetchingPostalCodes: false,
};

// eslint-disable-next-line
export default (state = initialState, action: AppAction) => {
  switch (action?.type) {
    case types.FETCH_POSTAL_CODES_START:
      return {
        ...state,
        isFetchingPostalCodes: true,
      };
    case types.FETCH_POSTAL_CODES_SUCCESS:
      return {
        ...state,
        postalcodes: action.payload.postalcodes,
        isFetchingPostalCodes: false,
      };
    case types.FETCH_POSTAL_CODES_FAILURE:
      return {
        ...state,
        isFetchingPostalCodes: false,
      };
    default:
      return { ...state };
  }
};
