/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { PAGINATION } from "../../../../common/constants/pagination";

export const limitAddresses = (addresses) => {
  if (!addresses.length) {
    return [];
  }

  const limitedAddresses = [addresses[0]];

  let numberOfCounters = addresses[0].listOfCounters
    ? addresses[0].listOfCounters.map((counter) => counter.counterId).length
    : 0;

  if (addresses.length < 99) {
    addresses.slice(1).forEach((address) => {
      const numberOfCountersForAddress = address.listOfCounters
        ? address.listOfCounters.map((counter) => counter.counterId).length
        : 0;
      if (
        numberOfCountersForAddress + numberOfCounters <=
        PAGINATION.ADDRESS_COUNTERS_PER_PAGE
      ) {
        limitedAddresses.push(address);
        numberOfCounters += numberOfCountersForAddress;
      }
    });
  } else {
    const flatedArray = addresses.flat();
    const objsArray = flatedArray.slice(0, 993);

    objsArray.slice(1).forEach((address) => {
      const numberOfCountersForAddress = address.listOfCounters
        ? address.listOfCounters.map((counter) => counter.counterId).length
        : 0;
      if (
        numberOfCountersForAddress + numberOfCounters <=
        PAGINATION.ADDRESS_COUNTERS_PER_PAGE
      ) {
        limitedAddresses.push(address);
        numberOfCounters += numberOfCountersForAddress;
      }
    });
  }
  return limitedAddresses.slice(0, 5);
};

export const searchAddresses = (addresses, searchValue) => {
  if (!searchValue) {
    return addresses;
  }

  return addresses.filter(
    (address) =>
      address.address.toLowerCase().includes(searchValue.toLowerCase()) ||
      address.listOfCountersAndInstallations
        ?.map((counterAndInstallation) => counterAndInstallation.counterId)
        .toString()
        .includes(searchValue) ||
      address.listOfCountersAndInstallations
        ?.map((counterAndInstallation) => counterAndInstallation.installationId)
        .toString()
        .includes(searchValue)
  );
};
