/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import { useHistory } from "react-router-dom";
import { PAGES } from "../../../common/constants/common";
import {
  Col,
  Row,
  Title,
  Text,
  Separator,
  Button,
} from "../../../components/ui";

const AdminLanding = () => {
  const history = useHistory();

  return (
    <Row align="center" direction="column">
      <Col lg={6} md={8} span={10} xl={3}>
        <Title align="center" level={2}>
          Vous êtes sur la page d’administration du portail SIE / TVT.
        </Title>
        <Text align="center" size="large">
          Cet accès est réservé aux personnes autorisées uniquement.
        </Text>
      </Col>
      <Separator size="large" />
      <Col>
        <Button onClick={() => history.push(PAGES.ADMIN_DASHBOARD)}>
          Connexion
        </Button>
      </Col>
    </Row>
  );
};

export default AdminLanding;
