/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppState } from "../../../../redux/store";

export const isComplexConsumptionSelector = (state: AppState) =>
  state.consumption["isComplex"];
export const consumptionSelector = (state: AppState) =>
  state.consumption["consumption"];
export const consumptionAddressesSelector = (state: AppState) =>
  state.consumption["addresses"];
export const consumptionHistorySelector = (state: AppState) =>
  state.consumption["history"];
export const consumptionHistoryForSecondChartSelector = (state: AppState) =>
  state.consumption["historyForSecondChart"];
export const isFetchingConsumptionAndAddressesSelector = (state: AppState) =>
  state.consumption["isFetchingConsumptionAndAddresses"];
export const isFetchingConsumptionHistorySelector = (state: AppState) =>
  state.consumption["isFetchingHistory"];
export const isFetchingConsumptionHistoryForSecondChartSelector = (
  state: AppState
) => state.consumption["isFetchingHistoryForSecondChart"];
export const isFetchingConsumptionCsvSelector = (state: AppState) =>
  state.consumption["isFetchingCsv"];
