/* eslint-disable linebreak-style */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

import classNames from "classnames/bind";
import { ReactNode } from "react";
import styles from "./StatusLabel.module.scss";

const cx = classNames.bind(styles);

const STATUSES = {
  Activé: styles.enabled,
  Payée: styles.enabled,
  Payé: styles.enabled,
  Ouvert: styles.enabled,
  Indisponible: styles.unavailable,
  Plombé: styles.available,
  "En cours": styles.available,
  "Délai échu": styles.unavailable,
  "Non disponible": styles.unavailable,
  Rappelé: styles.unavailable,
};

type StatusLabelProps = {
  status?:
    | "Activé"
    | "Payée"
    | "Payé"
    | "En cours"
    | "Délai échu"
    | "Non disponible"
    | "Ouvert"
    | "Indisponible"
    | "Plombé"
    | "Rappelé";
  border?: boolean;
  children?: ReactNode;
  className?: string;
};

const StatusLabel = ({
  status = "Payée",
  border = true,
  className = "",
  children,
}: StatusLabelProps) => (
  <p
    className={cx(STATUSES[status], { [styles.noBorder]: !border }, className)}
  >
    {children || status}
  </p>
);

export default StatusLabel;
