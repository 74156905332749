/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AxiosResponse } from "axios";
import {
  fetchCountersApi,
  fetchMeterReadingsAddressesApi,
  fetchMeterReadingsApi,
  fetchMeterReadingsHistoryApi,
  postCounterApi,
} from "../../../../api/meterReadings.api";
import { endOfDay } from "../../../../common/helpers/dates";
import { AppDispatch, AppState } from "../../../../redux/store";
import { toastSuccess } from "../../../../services";
import { userSelector } from "../../../auth/auth.selectors";
import * as types from "./meterReadings.actionTypes";
import {
  fetchCountersResponse,
  fetchMeterReadingsAddressesResponse,
  fetchMeterReadingsResponse,
  postCounterResponse,
} from "./meterReadings.types";

export const fetchMeterReadingsData =
  () => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_METER_READINGS_DATA_START });

      const responseMeterReadings: AxiosResponse<fetchMeterReadingsResponse> =
        await fetchMeterReadingsApi(id);
      const responseAddresses: AxiosResponse<fetchMeterReadingsAddressesResponse> =
        await fetchMeterReadingsAddressesApi(id);

      dispatch({
        type: types.FETCH_METER_READINGS_DATA_SUCCESS,
        payload: {
          meterReadings: responseMeterReadings.data.data,
          isComplex: responseAddresses.data.data.complexAddress,
          meterReadingsAddresses: responseAddresses.data.data.meterAddress,
        },
      });
    } catch (error) {
      dispatch({ type: types.FETCH_METER_READINGS_DATA_FAILURE });
    }
  };

export const fetchCounters =
  (params) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_COUNTERS_START });

      const response: AxiosResponse<fetchCountersResponse> =
        await fetchCountersApi(id, params);

      dispatch({
        type: types.FETCH_COUNTERS_SUCCESS,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({ type: types.FETCH_COUNTERS_FAILURE });
    }
  };

export const postCounter =
  (data) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id, name, lastName } = userSelector(getState());
      dispatch({ type: types.POST_COUNTER_START });
      const payload = {
        ...data,
        id,
        name,
        lastName,
      };
      const response: AxiosResponse<postCounterResponse> = await postCounterApi(
        id,
        payload
      );

      dispatch({
        type: types.POST_COUNTER_SUCCESS,
        payload: response.data.data,
      });
      toastSuccess({ message: "Modifié avec succès" });
    } catch (error) {
      dispatch({ type: types.POST_COUNTER_FAILURE });
    }
  };

export const clearCounters = () => ({ type: types.CLEAR_COUNTERS });

export const fetchMeterReadingsHistory =
  (params) => async (dispatch: AppDispatch, getState: () => AppState) => {
    try {
      const { id } = userSelector(getState());
      dispatch({ type: types.FETCH_METER_READINGS_HISTORY_START });

      const response = await fetchMeterReadingsHistoryApi(id, {
        ...params,
        endDate: endOfDay(new Date(params.endDate)),
      });
      dispatch({
        type: types.FETCH_METER_READINGS_HISTORY_SUCCESS,
        payload: {
          meterReadingsHistory: response.data.data,
          totalResultsMeterReadingsHistory: response.data.total,
          currrentPageMeterReadingsHistory: 1,
        },
      });
    } catch (error) {
      dispatch({ type: types.FETCH_METER_READINGS_HISTORY_FAILURE });
    }
  };

export const allAddressesUnchecked = () => ({
  type: types.ALL_ADDRESSES_UNCHECKED,
});
