/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
import { AppState } from "../../redux/store";

export const isFetchingPublicSelector = (state: AppState) =>
  state.public["isFetching"];
export const isSubmittingPublicSelector = (state: AppState) =>
  state.public["isSubmitting"];
