/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable spaced-comment */

const trackClickHandler = () => {
  let clicks: any = sessionStorage.getItem("clicks");
  if (!clicks) {
    sessionStorage.setItem("clicks", "1");
  } else {
    clicks = parseFloat(clicks);
    clicks += 1;
    sessionStorage.setItem("clicks", clicks.toString());
  }
};

export default trackClickHandler;
