/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-console */

import axios from "./http";

const apiAdminSuffix = "/admin";

export const impersonateUserApi = (userId: string) =>
  axios({
    method: "GET",
    url: `${apiAdminSuffix}/user/${userId}`,
  });

export const deleteUserApi = (userId: string) =>
  axios({
    method: "DELETE",
    url: `${apiAdminSuffix}/user/${userId}`,
  });

export const checkAdminConnectionApi = () =>
  axios({
    method: "GET",
    url: `${apiAdminSuffix}/impersonateauser`,
  });

export const extractNewsletterApi = () =>
  axios({
    method: "GET",
    url: `${apiAdminSuffix}/newslettersubscriptions/`,
  });

export const deleteNewsletterApi = () =>
  axios({
    method: "DELETE",
    url: `${apiAdminSuffix}/newslettersubscriptions/`,
  });

export const fetchUserApi = (userId: string) =>
  axios({
    method: "GET",
    url: `${apiAdminSuffix}/userinfo/${userId}`,
  });

export const resendVerificationEmailApi = ({ userId }: { userId: string }) =>
  axios({
    method: "POST",
    url: `${apiAdminSuffix}/resendverificationemail/${userId}`,
  });
