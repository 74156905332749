/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */

import { AppAction } from "../../../../redux/actionType";
import * as types from "./invoices.actionTypes";

const initialState = {
  addresses: [] as any,
  invoices: [],
  latestInvoice: null,
  invoiceReception: null,
  currentPageInvoices: 1,
  totalResultsInvoices: 0,
  isComplex: null,
  isFetchingAddresses: false,
  isFetchingInvoices: false,
  isFetchingLatestInvoice: false,
  isFetchingInvoiceReception: false,
  isSubmitting: false,
};

// eslint-disable-next-line
export default (state = initialState, action: AppAction) => {
  switch (action?.type) {
    case types.FETCH_INVOICES_ADDRESSES_START:
      return {
        ...state,
        isFetchingAddresses: true,
      };
    case types.FETCH_INVOICES_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: action.payload.addresses,
        isComplex: action.payload.isComplex,
        isFetchingAddresses: false,
        isSearchingAddresses: false,
      };
    case types.FETCH_INVOICES_ADDRESSES_FAILURE:
      return {
        ...state,
        isFetchingAddresses: false,
        isSearchingAddresses: false,
      };
    case types.FETCH_INVOICES_HISTORY_START:
      return {
        ...state,
        isFetchingInvoices: true,
      };
    case types.FETCH_INVOICES_HISTORY_SUCCESS:
      return {
        ...state,
        invoices: action.payload.invoices,
        currentPageInvoices: action.payload.currentPageInvoices,
        totalResultsInvoices: action.payload.totalResultsInvoices,
        isFetchingInvoices: false,
      };
    case types.FETCH_INVOICES_HISTORY_FAILURE:
      return {
        ...state,
        isFetchingInvoices: false,
      };
    case types.FETCH_LATEST_INVOICE_START:
      return {
        ...state,
        isFetchingLatestInvoice: true,
      };
    case types.FETCH_LATEST_INVOICE_SUCCESS:
      return {
        ...state,
        latestInvoice: action.payload,
        isFetchingLatestInvoice: false,
      };
    case types.FETCH_LATEST_INVOICE_FAILURE:
      return {
        ...state,
        isFetchingLatestInvoice: false,
      };
    case types.FETCH_INVOICE_RECEPTION_START:
      return {
        ...state,
        isFetchingInvoiceReception: true,
      };
    case types.FETCH_INVOICE_RECEPTION_SUCCESS:
      return {
        ...state,
        invoiceReception: action.payload,
        isFetchingInvoiceReception: false,
      };
    case types.FETCH_INVOICE_RECEPTION_FAILURE:
      return {
        ...state,
        isFetchingInvoiceReception: false,
      };
    case types.ALL_ADDRESSES_UNCHECKED:
      return {
        ...state,
        invoices: [],
        currentPageInvoices: 1,
        totalResultsInvoices: 0,
      };
    default:
      return { ...state };
  }
};
